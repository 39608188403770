<template>
	<div v-if="this.content" class="scrollButton">
		<a href="https://atlgroup.ru/serebrobyavenue" target="_blank">
			<v-btn id="scrollButton" v-show="showButton">
				<div class="font-scrollButton d-flex ml-3">
					<div v-html="this.content">
					</div>

					<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M28.8005 20.0163C28.802 20.1236 28.7815 20.2301 28.7402 20.3292C28.699 20.4283 28.6378 20.5178 28.5605 20.5923L12.5605 36.5923C12.4128 36.743 12.2115 36.8293 12.0005 36.8323C11.7843 36.8318 11.5771 36.7454 11.4245 36.5923C11.2769 36.4401 11.1943 36.2363 11.1943 36.0243C11.1943 35.8122 11.2769 35.6085 11.4245 35.4563L26.8165 20.0643L11.4405 4.5923C11.3655 4.51793 11.306 4.42945 11.2654 4.33196C11.2247 4.23447 11.2038 4.12991 11.2038 4.0243C11.2038 3.91869 11.2247 3.81412 11.2654 3.71664C11.306 3.61915 11.3655 3.53067 11.4405 3.4563C11.5123 3.37977 11.599 3.31877 11.6954 3.27708C11.7917 3.23538 11.8955 3.21387 12.0005 3.21387C12.1054 3.21387 12.2093 3.23538 12.3056 3.27708C12.4019 3.31877 12.4887 3.37977 12.5605 3.4563L28.5605 19.4563C28.6373 19.5277 28.6984 19.6145 28.7397 19.7109C28.7811 19.8073 28.8018 19.9114 28.8005 20.0163Z"
							fill="white"/>
					</svg>
				</div>
			</v-btn>

		</a>
	</div>
</template>
<script>
export default {
	data() {
		return {
			showButton: true,
		};
	},
	computed: {
		content() {
			return this.$store.state.common?.texts?.MAIN_OFFER?.PREVIEW_TEXT;
		},
	},
	methods: {},
	mounted() {
		window.addEventListener('scroll', this.handleScroll);
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.handleScroll);
	},
};
</script>
<style lang="scss">
.scrollButton {
}

#scrollButton {
	width: 300px;
	text-align: start !important;
	z-index: 5;
	position: fixed;
	bottom: 20px;
	right: 2%;
	color: #FFFFFF;
	border-radius: 30px;
	background-color: #6d1448 !important;
	border-color: #6d1448;
	text-wrap: wrap;
	@include up($sm) {
		//right: 3%;

		width: 330px;
		max-width: 330px;
	}

}

.font-scrollButton {
	max-width: 400px;
}
</style>