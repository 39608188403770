<template>
    <section class="pros">
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" class="pros__items">
                    <div class="pros__item" v-for="(item, index) of items" :key="index">
                        <img :src="item.img" alt="" class="pros__img mr-4"/>
                        <div class="pros__text ">
                            {{ item.text }}
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" class="pros__items">
                    <div class="pros__item" v-for="(item, index) of items2" :key="index">
                        <img :src="item.img" alt="" class="pros__img mr-4"/>
                        <div class="pros__text ">
                            {{ item.text }}
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>

export default {
    name: "pros",
    data() {
        return {
            items: [
                {
                    img: require('@/assets/pros/1.svg'),
                    text: 'Оформление в собственность в течение 1 дня'
                },
                /*{
                    img: require('@/assets/pros/2.svg'),
                    text: 'Ипотека от 4%, специальные условия по рассрочке'
                },*/
                {
                    img: require('@/assets/pros/3.svg'),
                    text: 'Скидка при 100% оплате'
                },
                {
                    img: require('@/assets/pros/icon3.svg'),
                    text: 'Экологически чистое место в окружении зелени'
                },
                {
                    img: require('@/assets/pros/5.svg'),
                    text: 'Неподалеку Удельный парк и ЦПКиО'
                }
            ],
            items2: [
                {
                    img: require('@/assets/pros/6.svg'),
                    text: '10-15 минут пешком до метро Пионерская и Комендантского проспекта'
                },
                {
                    img: require('@/assets/pros/icon2.svg'),
                    text: 'Рядом гипермаркеты и ТЦ, магазины «у дома»'
                },
                {
                    img: require('@/assets/pros/8.svg'),
                    text: 'Роскошное лобби для приема гостей'
                },
                {
                    img: require('@/assets/pros/icon1.svg'),
                    text: 'Круглосуточный консьерж-сервис'
                },
                {
                    img: require('@/assets/pros/10.svg'),
                    text: 'Кладовые, коворкинг, магазин и кафе в доме'
                }
            ]
        }
    }
}
</script>

<style lang="scss">
.pros {
    /*& .container {
        max-width: unset;
        @include up($md) {
            max-width: 1200px;
        }
        @include up($lg) {
            max-width: 1720px;
        }
    }*/
    &__img {
        height: 48px;
        width: 48px;
        @include up($md) {
            height: 56px;
            width: 56px;
        }
    }


    &__item {
        display: flex;
        align-items: center;
        max-width: 500px;
        margin: 0 auto;
        &:not(:last-child) {
            margin-bottom: 28px;
            @include up($md) {
                margin-bottom: 40px;
            }
        }
    }

    &__text {
        font-size: 18px;
        @include up($md) {
            font-size: 20px;
        }
    }
}
</style>