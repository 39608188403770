<template>
	<section class="howtobuy-calc">
		<v-container>
			<v-row class="align-md-baseline">
				<v-col cols="12" md="6">
					<h2>Рассчитайте срок окупаемости </h2>
					<span class="d-block mb-16">Рассчет окупаемости для апартаментов на 3-ем этаже</span>
					<div class="mb-10">
						<span class="mb-3 d-block">Стоимость помещения, руб.</span>
						<v-slider
							v-model="form.price"
							class="align-center"
							:max="priceOptions[priceOptions.length - 1]"
							:min="priceOptions[0]"
							:step="priceStep"
							hide-details
							@change="updateIncome"
						>
							<template v-slot:prepend>
								<v-text-field
									v-model="formattedPrice"
									class="mt-0 pt-0 h4"
									hide-details
									hide-spin-buttons
									:readonly="true"
								/>
							</template>
						</v-slider>
					</div>
					<div class="mb-10">
						<span class="mb-3 d-block">Средний доход от аренды за один год</span>
						<v-slider
							v-model="form.income"
							class="align-center"
							:max="incomeOptions[incomeOptions.length - 1]"
							:min="incomeOptions[0]"
							:step="incomeStep"
							hide-details
							@change="updatePriceByIncome"

						>
							<template v-slot:prepend>
								<v-text-field
									v-model="formattedIncome"
									class="mt-0 pt-0 h4"
									hide-details
									hide-spin-buttons
									:readonly="true"
								></v-text-field>
							</template>
						</v-slider>
					</div>
					<div class="mb-10">
						<span class="mb-3 d-block">Срок окупаемости (с момента начала сдачи), лет</span>
						<v-slider
							v-model="form.years"
							class="align-center"
							:max="yearsOptions[yearsOptions.length - 1]"
							:min="yearsOptions[0]"
							:step="yearsStep"
							hide-details
							@change="updatePriceByYears"
						>
							<template v-slot:prepend>
								<v-text-field
									v-model="form.years"
									class="mt-0 pt-0 h4"
									hide-details
									hide-spin-buttons
									type="number"
									:max="yearsOptions[yearsOptions.length - 1]"
									:min="yearsOptions[0]"
									:step="yearsStep"

								/>
							</template>
						</v-slider>
					</div>
					<!--                    <div class="mb-12">-->
					<!--                        Платеж от-->
					<!--                        <span class="primary-1" v-if="this.monthlyPayment < 0"-->
					<!--                        >0 руб./мес.</span-->
					<!--                        >-->
					<!--                        <span class="primary-1" v-else-->
					<!--                        >{{ monthlyPayment | nf }} руб./мес.</span-->
					<!--                        >-->
					<!--                    </div>-->
					<popup title="Рассчитайте срок окупаемости">Оставить заявку</popup>
<!--					<span class="mt-3 mb-12 d-block">
            Расчеты являются приблизительными. Актуальные условия программ
            банков уточняйте у нашего ипотечного менеджера по тел.
                        <a :href="'tel:'+ $store.state.PHONE.replace(/[^0-9]/g, '')">{{ $store.state.PHONE }}</a>
                        или почте
                        <a :href="'mailto:'+ $store.state.EMAIL">{{ $store.state.EMAIL }}</a>.
                    </span>-->
				</v-col>
<!--				<v-col cols="12" md="6">
					<button
						v-on:click="isHidden = !isHidden"
						class="d-md-none howtobuy-calc__hide"
					>
						<v-img class="mr-4" :src="this.buttonIcon"/>
						{{ this.buttonText }}
					</button>
					<div v-if="!isHidden" class="howtobuy-calc__banks">
						<h4 class="mb-10 mt-16 mt-md-0">Наши банки-партнеры</h4>
						<div
							v-for="(item, index) of items"
							:key="index"
							class="howtobuy-calc__bank"
						>
							<v-img
								class="mb-5 mb-md-10 howtobuy-calc__logo"
								:src="item.logo"
							/>
							<div class="d-sm-flex justify-space-between">
								<div class="mb-5">
									Ставка
									<span class="d-block mt-2">
                    {{ item.percent }}
                  </span>
								</div>
								<div class="mb-5">
									Первый взнос
									<span class="d-block mt-2">
                    {{ item.firstPay }}
                  </span>
								</div>
								<div>
									Срок
									<span class="d-block mt-2">
                    {{ item.time }}
                  </span>
								</div>
							</div>
						</div>
					</div>
					<div class="howtobuy-calc__banks d-none d-md-block">
						<h4 class="mb-10 mt-16 mt-md-0">Наши банки-партнеры</h4>
						<div
							v-for="(item, index) of items"
							:key="index"
							class="howtobuy-calc__bank"
						>
							<v-img
								class="mb-5 mb-md-10 howtobuy-calc__logo"
								:src="item.logo"
							/>
							<div class="d-md-flex justify-space-between">
								<div class="mb-5">
									Ставка
									<span class="d-block mt-2">
                    {{ item.percent }}
                  </span>
								</div>
								<div class="mb-5">
									Первый взнос
									<span class="d-block mt-2">
                    {{ item.firstPay }}
                  </span>
								</div>
								<div>
									Срок
									<span class="d-block mt-2">
                    {{ item.time }}
                  </span>
								</div>
							</div>
						</div>
					</div>
				</v-col>-->
			</v-row>
		</v-container>
	</section>
</template>

<style lang="scss">
.howtobuy-calc {
	& .popup {
		max-width: 360px;
	}

	&__container {
		max-width: unset !important;
		@include up($md) {
			max-width: 1200px !important;
		}
		@include up($lg) {
			max-width: 1720px !important;
		}
	}

	.v-input {
		display: block;

		&__slot {
			@include up($md) {
				max-width: 450px;
			}
			@include up($lg) {
				max-width: 650px;
			}
		}
	}

	.v-text-field > .v-input__control > .v-input__slot:before {
		display: none;
	}

	.v-text-field > .v-input__control > .v-input__slot:after {
		display: none;
	}

	.v-text-field input {
		padding: 0;
	}

	h2 {
		margin-bottom: 40px;
		@include up($md) {
			//margin-bottom: 80px;
			//max-width: 80px;
		}
	}

	input[type="range"] {
		width: 100%;
		height: 10px;
		//overflow: hidden;
		cursor: pointer;
		position: relative;
		@include up($md) {
			max-width: 630px;
		}

		&:before {
			position: absolute;
			content: "";
			left: 0;
			top: 0;
			width: 10px;
			height: 10px;
			background: $black;
			border-radius: 50%;
		}
	}

	input[type="range"],
	input[type="range"]::-webkit-slider-runnable-track,
	input[type="range"]::-webkit-slider-thumb {
		-webkit-appearance: none;
	}

	input[type="range"]::-webkit-slider-runnable-track {
		width: 100%;
		height: 2px;
		background: $primary-2;
		border-radius: 20px;
		position: relative;
	}

	input[type="range"]::-webkit-slider-thumb {
		height: 10px;
		width: 10px;
		margin-top: -4px;
		background: $primary-2;
		border-radius: 50%;
		//border: 2px solid white;
	}

	&__hide {
		display: flex;
		align-items: center;
	}

	&__banks {
		@include up($md) {
			margin-left: auto;
			max-width: 610px;
		}
	}

	&__bank {
		padding: 24px;
		position: relative;

		&:not(:last-child) {
			margin-bottom: 40px;
		}

		&:before {
			position: absolute;
			content: "";
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 1.5px solid transparent;
			border-image: linear-gradient(309.68deg, #ffffff 0%, #979eb4 84.1%);
			border-image-slice: 1;
		}

		@include up($lg) {
			width: 610px;
			height: 224px;
			padding: 40px;
		}
	}

	&__logo {
		max-height: 24px;
		width: fit-content;
	}
}
</style>

<script>
import BtnForm from "@/components/btn-form";
import Popup from "@/components/popup";
import {nf} from "@/utils/string";

export default {
	name: "howtobuy-calc",
	components: {BtnForm, Popup},
	data() {
		return {
			form: {
				price: 7000000,
				income: 1086500,
				years: 10,
			},
			priceOptions: [7000000, 8000000, 11750000, 13000000, 14400000],
			incomeOptions: [1086500, 1282250, 1534000, 1617250, 1777500],
			yearsOptions: [8, 9, 11, 12],
			priceStep: 10000,
			incomeStep: 10,
			yearsStep: 1,
			price: {
				min: 7000000,
				max: 15000000,
				step: 50000,
				slider: 0, // initial value
			},
			income: {
				min: 0,
				max: 20000000,
				step: 1,
				slider: 0, // initial value
			},
			years: {
				min: 0,
				max: 30,
				step: 0.1,
				slider: 10, // initial value
			},
			interestRate: 8,
			percentOfPrice: 15,

			isHidden: true,
			buttonText: "Банки-партнеры",
			buttonIcon: require("@/assets/Howtobuy/howtobuy-calc/show.svg"),

			items: [
				{
					logo: require("@/assets/Howtobuy/howtobuy-calc/bank1.png"),
					percent: "8,0%",
					firstPay: "от 15%",
					time: "до 30 лет",
				},
				{
					logo: require("@/assets/Howtobuy/howtobuy-calc/bank2.png"),
					percent: "8,2%",
					firstPay: "от 10%",
					time: "до 30 лет",
				},
				{
					logo: require("@/assets/Howtobuy/howtobuy-calc/bank3.png"),
					percent: "9,0%",
					firstPay: "от 15%",
					time: "до 25 лет",
				},
			],
		};
	},

	computed: {
		formattedIncome() {
			return this.form.income.toLocaleString('ru-RU');
		},
		formattedPrice() {
			return this.form.price.toLocaleString('ru-RU');
		},
		monthlyPayment() {
			let credit = this.price.slider - this.income.slider,
				monthlyInterest = this.interestRate / 100 / 12,
				month = this.years.slider * 12;

			return (
				(credit * monthlyInterest * Math.pow(1 + monthlyInterest, month)) /
				(Math.pow(1 + monthlyInterest, month) - 1)
			).toFixed();
		},
		initialFee() {
			return ((this.price.slider / 100) * this.percentOfPrice).toFixed();
		},
	},

	watch: {
		// "price.slider": {
		//   immediate: true,
		//   handler(val, old) {
		// 	if (val !== old) {
		// 	  this.form.price = nf(this.price.slider, 0, ",", " ");
		//
		// 	  this.years.slider = Math.ceil(this.price.slider / this.income.slider);
		// 	}
		//   },
		// },
		// "income.slider": {
		//   immediate: true,
		//   handler(val, old) {
		// 	if (val !== old) {
		// 	  this.form.income = nf(this.income.slider, 0, ",", " ");
		//
		// 	  this.years.slider = Math.ceil(this.price.slider / this.income.slider);
		// 	}
		//   },
		// },
		// "years.slider": {
		//   immediate: true,
		//   handler(val, old) {
		// 	if (val !== old) {
		// 	  this.form.years = this.years.slider;
		// 	  this.income.slider = (this.price.slider / this.years.slider).toFixed();
		// 	}
		//   },
		// },

		isHidden() {
			if (this.isHidden) {
				this.buttonText = "Банки-партнеры";
				this.buttonIcon = require("@/assets/Howtobuy/howtobuy-calc/show.svg");
			} else if (!this.isHidden) {
				this.buttonText = "Скрыть банки-партнеры";
				this.buttonIcon = require("@/assets/Howtobuy/howtobuy-calc/hide.svg");
			}
		},
	},
	methods: {

		updateIncome() {
			const price = this.form.price;
			const priceDataMap = {
				7000000: {income: 1086500, years: 8},
				8000000: {income: 1282250, years: 9},
				11750000: {income: 1534000, years: 11},
				13000000: {income: 1617250, years: 12},
				14400000: {income: 1777500, years: 12},
			};

			const sortedPrices = Object.keys(priceDataMap).sort((a, b) => a - b);
			const closestPrice = sortedPrices.reduce((prev, curr) =>
				Math.abs(curr - price) < Math.abs(prev - price) ? curr : prev
			);

			const priceData = priceDataMap[closestPrice] || {income: 0, years: 0};
			this.form.income = priceData.income;
			this.form.years = priceData.years;

			const numericClosestPrice = parseFloat(closestPrice);

			if (!isNaN(numericClosestPrice)) {
				this.price.slider = numericClosestPrice.toFixed(2);
			} else {
				console.error('closestPrice is not a number:', closestPrice);
			}

			this.years.slider = priceData.years;

		},
		updatePriceByYears() {
			const years = this.form.years;
			const priceDataMap = {
				8: {price: 7000000, income: 1086500},
				9: {price: 8000000, income: 1282250},
				11: {price: 11750000, income: 1534000},
				12: {price: 13000000, income: 1617250},
				// 12: { price: 14400000, income: 1777500 },
			};

			const sortedYears = Object.keys(priceDataMap).sort((a, b) => a - b);
			const closestYears = sortedYears.reduce((prev, curr) =>
				Math.abs(curr - years) < Math.abs(prev - years) ? curr : prev
			);

			const priceData = priceDataMap[closestYears] || {price: 0, income: 0};
			this.form.price = priceData.price;
			this.form.income = priceData.income;

			const numericClosestYears = parseFloat(closestYears);

			if (!isNaN(numericClosestYears)) {
				this.price.slider = numericClosestYears.toFixed(2);
			} else {
				console.error('closestYears is not a number:', closestYears);
			}
			this.income.slider = priceData.income;
		},
		updatePriceByIncome() {
			const income = this.form.income;
			const priceDataMap = {
				1086500: {price: 7000000, years: 8},
				1282250: {price: 8000000, years: 9},
				1534000: {price: 11750000, years: 11},
				1617250: {price: 13000000, years: 12},
				1777500: {price: 14400000, years: 12},
			};

			const sortedIncomes = Object.keys(priceDataMap).sort((a, b) => a - b);
			const closestIncome = sortedIncomes.reduce((prev, curr) =>
				Math.abs(curr - income) < Math.abs(prev - income) ? curr : prev
			);

			const priceData = priceDataMap[closestIncome] || {price: 0, years: 0};
			this.form.price = priceData.price;
			this.form.years = priceData.years;


			const numericClosestIncome = parseFloat(closestIncome);

			if (!isNaN(numericClosestIncome)) {
				this.income.slider = numericClosestIncome.toFixed(2);
			} else {
				console.error('closestIncome is not a number:', closestIncome);
			}

			this.years.slider = priceData.years;
		},
		// trimText(prop) {
		//   this.form[prop] = this.form[prop].replace(/\D+/g, "");
		// },
		// validateincome() {
		//   let v = Number(this.form.income.replace(/\D+/g, ""));
		//   // let price = Number(this.form.price.replace(/\D+/g, ""));
		//   //
		//   // if (isNaN(v)) v = this.initialFee;
		//   // else if (v < this.initialFee) {
		//   //     v = this.initialFee;
		//   //     console.log("PRICE", v);
		//   // } else if (v > price) {
		//   //     v = price;
		//   // }
		//   this.income.slider = v; // todo allow slider to input numbers that differ from step
		//   // // format years in input
		//   this.form.income = nf(v, 0, ",", " ");
		// },
		// validateincomeSlider() {
		//   // console.log("PRICE", this.initialFee);
		//   // let price = Number(this.form.price.replace(/\D+/g, ""));
		//   //
		//   // if (this.income.slider < this.initialFee) {
		//   //     this.income.slider = this.initialFee;
		//   // } else if (this.income.slider > price) {
		//   //     this.income.slider = price;
		//   // }
		//   this.form.income = this.income.slider; // todo allow slider to input numbers that differ from step
		//
		//   // // format years in input
		//   this.form.income = nf(this.income.slider, 0, ",", " ");
		//   // console.log("PRICE", this.form.income);
		// },
		// validatePrice() {
		//   let v = Number(this.form.price.replace(/\D+/g, ""));
		//   if (isNaN(v)) v = this.price.min;
		//   else if (v < this.price.min) {
		// 	this.form.price = this.price.min;
		//   } else if (v > this.price.max) {
		// 	this.form.price = this.price.max;
		//   }
		//   this.price.slider = v; // todo allow slider to input numbers that differ from step
		//
		//   // // format years in input
		//   this.form.price = nf(v, 0, ",", " ");
		// },
		// validateYears() {
		//   if (Number(this.form.years) < this.years.min) {
		// 	this.form.years = this.years.min;
		//   } else if (Number(this.form.years) > this.years.max) {
		// 	this.form.years = this.years.max;
		//   }
		//   this.years.slider = Number(this.form.years);
		//
		//   // format years in input
		//   //this.form.years = nf(this.form.years, 0, ",", " ");
		// },
	},
};

// slider bug
// 1. focus 2. value  3. enter (4 times)
</script>