<template>
  <section class="flats-main">
    <v-container>
      <div class="flats-main__choice">
        <v-img class="relative" src="@/assets/Flats/flats-main/exterrior.jpg/" contain>

          <svg id="choice-svg" class="flats-main__choice-home" viewBox="0 0 1578 1002" fill="none"
               xmlns="http://www.w3.org/2000/svg" v-html="map.svg">
          </svg>

        </v-img>

        <h4 class="mt-5 d-sm-none">Выберите этаж:</h4>

        <flats-main-popup v-if="currentLand"
                          :item="currentLand"
        />

        <div class="flats-main__aside d-flex justify-space-between text-center">
          <div class="d-flex flex-column justify-space-between">
            <span class="size-xs">1 секция</span>
            <button v-for="(item, index) of queueTwo"
                    :id="item.ID"
                    :key="index"
                    class="flats-main__step flats-main__step-first"
                    :class="{active: item.isActive}"
                    @click="$router.push({name: 'flats-choice', params: {id: item.ID}})"
            >
              {{ queueTwo.length - index }}
            </button>
          </div>
          <div class="flats-main__aside-text font-sm">
            <i class="text-line"></i>
            <span>этажи</span>
            <i class="text-line --2"></i>
          </div>
          <div class="d-flex flex-column justify-space-between">
            <span class="size-xs">2 секция</span>
            <button v-for="(item, index) of queueOne"
                    :id="item.ID"
                    :key="index"
                    class="flats-main__step flats-main__step-second"
                    :class="{active: item.isActive}"
                    @click="$router.push({name: 'flats-choice', params: {id: item.ID}})"
            >
              {{ queueOne.length - index }}
            </button>
          </div>
        </div>

      </div>
      <v-row class="mt-5">
        <v-col cols="12" md="6">

        </v-col>
        <v-col cols="12" md="6">
          В Serebro by Avenue-Apart два корпуса – 6 этажей (высота потолка – 4.6 м) и 9 этажей (высота потолка – 3 м).
          Жильцам, гостям и инвесторам Комплекса предлагаются апартаменты площадью от 20 м2. Для
          вашего удобства мы создали конструктор, где вы можете указать желаемые параметры: этаж, площадь, стоимость.
          Выбрав подходящие Вам параметры, Вы получите список свободных помещений с планировкой. Благодаря экологической
          чистоте района и отсутствию машин во дворе, жильцам и гостям Serebro by Avenue-Apart будет комфортно в
          апартаментах, расположенных на любом этаже. Выбирайте, опираясь только на личные предпочтения, не
          переживая за свой комфорт.
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import FlatsMainPopup from "@/components/Flats/flats-main-popup";
import {items, map} from "@/store/tmp/tmp-flats-main";

export default {
  name: "flats-main",
  components: {FlatsMainPopup},
  data() {
    return {
      currentLand: null,
    }
  },
  computed: {
    items() {
      return items;
    },
    map() {
      return map;
    },
    levels() {
      return this.$store.state.page?.LevelListController?.levels;
    },
    queueOne() {
      return this.$store.state.page?.LevelListController?.levels.slice(0, 9).reverse();
    },
    queueTwo() {
      return this.$store.state.page?.LevelListController?.levels.slice(9, 15).reverse();
    },
  },
  methods: {
    flatItems() {
      const floors = this.levels;
      return floors;
    },
    onMouseOverPath(e, path) {
      this.flatItems().forEach(e => {
        if (e.ID === path.id) {
          e.isActive = true;
          this.currentLand = e;
        }
      });
    },
    onMouseOutPath(e, path) {
      this.currentLand = null;
      this.flatItems().forEach(e => {
        if (e.ID === path.id) e.isActive = false;
      });
    },
    onMouseOverQueue(e, queue, paths) {
      this.currentLand = this.flatItems().find(e => e.ID === queue.id);
      paths.filter(path => {
        if (path.id === queue.id) path.classList.add('active');
      });
    },
    onMouseOutQueue(e, queue, paths) {
      this.currentLand = null;
      paths.filter(path => {
        if (path.id === queue.id) path.classList.remove('active');
      });
    }
  },
  mounted() {
    const img = document.getElementById("choice-svg");
    const queues = document.querySelectorAll(".flats-main__step");
    const paths = [...img.childNodes]

    queues.forEach(queue => {
      queue.addEventListener('mouseover', (e) => this.onMouseOverQueue(e, queue, paths));
      queue.addEventListener('mouseout', (e) => this.onMouseOutQueue(e, queue, paths));
    });

    paths.forEach(path => {
      const land = paths.find(el => el.id);
      if (land) {
        path.addEventListener("mouseover", (e) => this.onMouseOverPath(e, path));
        path.addEventListener("mouseout", (e) => this.onMouseOutPath(e, path));
        path.addEventListener("click", () => {
          this.$router.push({name: 'flats-choice', params: {id: path.id}});
        });
      }
    });

  },
}
</script>

<style lang="scss">
.flats-main {

  h1 {
    color: $black;
  }

  &__btn {
    position: absolute;
    top: 40px;
    left: 32px;
  }

  &__choice {
    position: relative;

    .v-image {
      overflow: visible;

      @include up($lg) {
        min-width: 1580px;
        min-height: 1002px;
      }
    }

    &-home {
      position: absolute;
      left: 5px;
      right: 0;

      path[id] {
        cursor: pointer;

        &.active {
          fill-opacity: 0.6;
        }

        &:hover {
          @include transition();
          fill-opacity: 0.6;
        }
      }
    }
  }

  &__aside {
    position: relative;
    width: 128px;
    height: 549px;
    margin: 30px auto;

    @include up($sm) {
      position: absolute;
      top: 10%;
      right: 120px;
    }
    @include up($lg) {
      top: 25%;
    }

    &-text {
      display: flex;
      align-items: center;
      position: relative;

      span {
        display: block;
        transform: rotate(-90deg);
      }

      .text-line {
        position: absolute;
        width: 1px;
        height: 43%;
        background: $mid-grey;
        top: 0;
        right: calc(50% - 0.5px);

        &.--2 {
          bottom: 0;
          top: auto;
        }
      }
    }
  }

  &__step {
    flex: 0;
    width: 100%;
    background-color: $white;

    &.active {
      background: $primary-2;
      color: $white;
    }

    &-first {
      padding: 20px 14px;
    }

    &-second {
      padding: 7px 14px;
    }

    @include transition();

    &:hover {
      background: $primary-2;
      color: $white;
    }

    &:active {
      background: $primary-1;
    }

    &:disabled {
      background: $light-grey;
      color: $mid-grey;
    }
  }
}
</style>