<template>
    <section class="server-error">
        <v-container>
            <v-row class="relative z-index-1">
                <v-col>
                    <h1 class="mb-10 mb-md-16">
                        Ошибка 500
                    </h1>
                    <p>
                        На сервере произошла непредвиденная ошибка. Пожалуйста, подождите: она вскоре будет исправлена.
                    </p>
                    <btn-error>Вернуться на главную</btn-error>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<style lang="scss">
.server-error {
    position: relative;
    height: calc(100vh - 98px);
    color: $white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    background-image: url("@/assets/Error/error-xs.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;

    &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background: #6D7E88;
        opacity: 0.4;
    }

    @include up($sm) {
        background-image: url("@/assets/Error/error-lg.jpg");
    }

    p {
        max-width: 740px;
        margin: 0 auto 64px;
    }
}
</style>
<script>
import BtnError from "@/components/btn-error";

export default {
    components: {BtnError}
}
</script>