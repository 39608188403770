<template>
	<section class="project-progress relative">
		<v-container class="relative">
			<h2 class="relative z-index-1">
				Ход строительства
			</h2>
			<div class="project-progress__btns">
				<div class="project-progress__btn project-progress__btn-prev">
					<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M28 20L18 14.2265V25.7735L28 20ZM12 21H19V19H12V21Z" fill="white"/>
					</svg>
				</div>
				<div class="project-progress__btn project-progress__btn-next">
					<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M28 20L18 14.2265V25.7735L28 20ZM12 21H19V19H12V21Z" fill="white"/>
					</svg>
				</div>
			</div>
			<div class="swiper mySwiper">
				<div class="swiper-wrapper">
					<div class="swiper-slide" v-for="(item, index) of items" :key="index">
						<v-img class="project-progress__img" :src="item.img" alt="Slide"/>
					</div>
				</div>
			</div>

		</v-container>
	</section>
</template>

<script>
import Swiper, {Navigation} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default {
	data() {
		return {
			items: [
				{img: require('@/assets/Project/project-progress/1-min.jpg')},
				{img: require('@/assets/Project/project-progress/2-min.jpg')},
				{img: require('@/assets/Project/project-progress/3-min.jpg')},
				{img: require('@/assets/Project/project-progress/4-min.jpg')},
				{img: require('@/assets/Project/project-progress/5-min.jpg')},
				{img: require('@/assets/Project/project-progress/6-min.jpg')},
				{img: require('@/assets/Project/project-progress/7-min.jpg')},
				{img: require('@/assets/Project/project-progress/8-min.jpg')},
				{img: require('@/assets/Project/project-progress/9-min.jpg')},
				{img: require('@/assets/Project/project-progress/10-min.jpg')},
				{img: require('@/assets/Project/project-progress/11-min.jpg')},
			],
		}
	},
	mounted: function () {
		var swiper = new Swiper(".mySwiper", {
			slidesPerView: 1.1,
			spaceBetween: 10,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			modules: [Navigation],
			navigation: {
				nextEl: '.project-progress__btn-next',
				prevEl: '.project-progress__btn-prev',
			},
			breakpoints: {
				640: {
					slidesPerView: 2.1,
					spaceBetween: 20,
				},
				768: {
					slidesPerView: 2.1,
					spaceBetween: 40,
				},
				1024: {
					slidesPerView: 2.1,
					spaceBetween: 50,
				},
			},
		});
	},
};
</script>

<style lang="scss">
.project-progress {

	h2 {
		height: 80px;
		margin-bottom: 100px;
		@include up($sm) {
			margin-bottom: 60px;
			height: unset;
		}
	}
	.swiper {
		width: 100%;
		height: 100%;
	}

	.swiper-slide {
		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
		max-width: 632px;

		@include up($sm) {
			min-width: 372px;
		}
	}

	.swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__btns {
		display: flex;
		position: absolute;
		top: 135px;
		z-index: 2;
		cursor: pointer;
		@include up($sm) {
			top: 30px;
			right: 25px;
		}
		@include up($md) {
			top: 45px;
			right: 32px;
		}
		@include up($lg) {
			top: 45px;
			right: 68px;
		}
	}

	&__btn-next {
		//background-image: url("@/assets/Location/location-distance/slider-arrow.svg");
		width: 40px;
		height: 40px;
		background: $mid-grey;
		transition: all 0.2s ease;
	}
	&__btn-prev {
		//background-image: url("@/assets/Location/location-distance/slider-arrow.svg");
		transform: rotate(180deg);
		width: 40px;
		height: 40px;
		background: $mid-grey;
		margin-right: 10px;
		transition: all 0.2s ease;
	}

	&__btn-next,
	&__btn-prev {
		&:hover {
			background: $dark-grey;
		}
		&:active {
			background: $dark-grey;
		}

	}
	.swiper-button-disabled {
		background: $light-grey;
	}

	&__img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
</style>