import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/router/routes';
import store from "@/store";
import {mergeObjects} from "@/utils/utils";


Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const scrollTo = (hash = null, offset = 0, behavior = 'smooth') => {
    let top = 0;

    if (typeof hash === "string") {
        hash = hash.match(/^\#.+/) ? hash.substring(1) : hash;
        const el = document.getElementById(hash);
        //console.log('Rect:', el.getBoundingClientRect());
        if (el) top = el.getBoundingClientRect().top + window.scrollY + offset;
        else {
            console.warn("Scroll to element not found: " + hash);
            return;
        }
    } else if (typeof hash === "number") top = hash;

    window.scrollTo({
        behavior,
        left: 0,
        top
    });
};


router.beforeEach((to, from, next) => {

    // загружаем данные текущего роута (кэшируемые), в т.ч. common данные:
    if (to.meta?.api) {
        //store.state.isLoading = true;

        let params = mergeObjects({}, {
            action: to.meta.api,
            method: to.meta.method,
            params: to.params
        }, to.meta);
        store.dispatch('fetchPage', params)
            .then(() => {
                next();
            })
            .catch((err) => {
                console.error("API error: ", err);
                if (err.code === Config.API_EXCEPTION_NOT_FOUND) {
                    router.replace({name: 'error404'});
                } else {
                    router.replace({name: 'error500'});
                }
                //next();
            });
    } else {
        next();
    }
});


router.afterEach((to, from) => {
    //console.log(to.hash);
    if(to.hash) {
        setTimeout(() => {
            scrollTo(to.hash);
        }, 150);
    } else {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        });
    }
})

export default router;

