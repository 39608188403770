<template>
    <div class="howtobuy">
        <howtobuy-main/>
        <howtobuy-terms/>
        <howtobuy-calc/>
        <howtobuy-questions/>
	 	 <scrollButton />
    </div>
</template>

<style lang="scss">
.howtobuy {
    overflow: hidden;

    section {
        padding-bottom: 80px;
        @include up($md) {
            padding-bottom: 120px;
        }
    }

    &-calc {
        padding-bottom: 0 !important;
        @include up($md) {
            padding-bottom: 80px !important;
        }
    }
}
</style>
<script>
import HowtobuyMain from "@/components/Howtobuy/howtobuy-main";
import HowtobuyTerms from "@/components/Howtobuy/howtobuy-terms";
import HowtobuyCalc from "@/components/Howtobuy/howtobuy-calc";
import HowtobuyQuestions from "@/components/Howtobuy/howtobuy-questions";
import ScrollButton from "@/components/scrollButton.vue";

export default {
    components: {HowtobuyQuestions, HowtobuyCalc, HowtobuyTerms, HowtobuyMain,ScrollButton,}
}
</script>