<template>
  <div class="home">
    <home-intro />
    <!-- <pros /> -->
    <home-about />
    <home-design />
	<home-apartments/>
    <home-location />
    <home-infrastructure />
	  <home-news/>
	<scrollButton />
  </div>
</template>

<script>
import HomeIntro from "@/components/Home/home-intro";
import HomeAbout from "@/components/Home/home-about";
import HomeDesign from "@/components/Home/home-design";
import HomeLocation from "@/components/Home/home-location";
import HomeInfrastructure from "@/components/Home/home-infrastructure";
import Pros from "@/components/pros";
import HomeApartments from "@/components/Home/home-apartments.vue";
import ScrollButton from "@/components/scrollButton.vue";
import HomeNews from "@/components/Home/home-news";

export default {
  components: {
	  HomeNews,
	ScrollButton,
	HomeApartments,
    HomeIntro,
    HomeAbout,
    HomeDesign,
    HomeLocation,
    HomeInfrastructure,
    Pros,
  },
};
</script>

<style lang="scss">
.home {

  &-intro {
    margin-bottom: 410px;

    @include up($sm) {
      margin-bottom: 70px;
    }
    @include up($md) {
      margin-bottom: -40px;
      margin-top: 95px;
    }
    @include up($lg) {
      margin-bottom: 80px;
    }
  }
  &-about {
    margin-bottom: 30px;
    @include up($sm) {
      margin-bottom: 100px;
    }
  }

  &-design {
    margin-bottom: 130px;
        @include up($sm) {
      margin-bottom: 270px;
    }
    @include up($md) {
      margin-bottom: 350px;
    }

  }
  &-apartments {
	margin-bottom: 130px;
	@include up($sm) {
	  margin-bottom: 270px;
	}
	@include up($md) {
	  margin-bottom: 350px;
	}

  }

  &-location {
    margin-bottom: -250px;
    @include up($sm) {
      margin-bottom: 50px;
    }
    @include up($md) {
      margin-bottom: 70px;
    }
    @include up($lg) {
      margin-bottom: 130px;
    }
  }
  &-infrastructure {
    margin-bottom: 50px;
    @include up($lg) {
      margin-bottom: 150px;
    }
  }
	&-news {
		margin-bottom: 120px;
		@include up($lg) {
			margin-bottom: 150px;
		}
	}

}
</style>