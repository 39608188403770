let state = {

    // данные из БЕ - переустанавливаются при каждом fetchPage
    response: {},	// объект ответа от БЕ в чистом виде
    session: {},	// данные текущей сессии

    page: {},			// индивидуальные для каждой страницы
    currentPage: {},	// текущая страница
    common: {},

    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,

    menuItems: [
        {name: "О проекте", to: {name: "project"}},
        {name: "Апартаменты", to: {name: "flats"}},
        // {name: "Апартаменты", to: {name: "home"}},
        {name: "Расположение", to: {name: "location"}},
        {name: "Как купить", to: {name: "howtobuy"}},
        {name: "Новости", to: {name: "news"}},
        {name: "Контакты", to: {name: "contacts"}},
    ],

    isBurgerMenuShown: false,

    ADDRESS: 'Санкт-Петербург, Серебристый б-р, 18 к. 1',
    PHONE: '8 (812) 918 55 18',
    EMAIL: 'sale@serebro-avenue.ru',

};
export default state;