<template>
  <section class="news-item">
	<v-container class="mb-12 mb-lg-10 ">
	  <router-link class="d-block mt-10" :to="{ name: 'news' }">
		<btn class="news-item__btn">Назад</btn>
	  </router-link>
	  <h4 class="mt-12 mt-lg-16"> {{ item.NAME }} </h4>
	  <span class="size-xs mt-5 d-block"> {{ formatDate(item.ACTIVE_FROM) }} </span>
	</v-container>

	<div class="swiper relative swiper-news-item d-lg-none">
	  <div class="swiper-wrapper">
		<div v-for="(slide, index) of slides" :key="index" class="swiper-slide">
		  <v-img  @click="openImageFullScreen(slide)"
			  :lazy-src="lazyImg"
			  :src="slide"
			  alt="Slide"
		  />
		</div>
	  </div>
	</div>
	<v-container class="d-lg-none">
	  <div class="d-flex mt-4">
		<div class="swiper-button-prev swiper-new-prev"></div>
		<div class="swiper-button-next swiper-new-next"></div>

	  </div>
	</v-container>

	<div class="swiper relative swiper-news-item d-none d-lg-block">
	  <div class="swiper-wrapper">
		<div v-for="(slide, index) of slides" :key="index" class="swiper-slide">
		  <v-img @click="openImageFullScreen(slide)"
			  :lazy-src="lazyImg"
			  :src="slide"
			  alt="Slide"
		  />
		</div>
	  </div>
	</div>
	<v-container>
	  <div class="mt-12 d-none d-lg-flex swiper-button-wrap">
		<div class="swiper-button-prev swiper-new-prev"></div>
		<div class="swiper-button-next swiper-new-next"></div>

	  </div>
	  <div class="news-item__content">
		<p class="font1 my-md-16" v-html="item.DETAIL_TEXT"></p>
	  </div>
	</v-container>
	<div v-if="relativeNews" class="mb-2 news-item__news-block">
	  <v-container>
		<div class="d-sm-flex align-baseline justify-space-between">
		  <h4 class="mb-8 mb-md-16">Читайте далее</h4>
		  <router-link :to="{ name: 'news' }">
			<btn class="d-none d-sm-block">
			  Все новости
			</btn>
		  </router-link>
		</div>
		<ul>
		  <li v-for="(relativeNew, index) of relativeNews" :key="index">
			<router-link :to="relativeNew.CODE">
			  <v-img :src="relativeNew.PREVIEW_PICTURE"/>
			  <div class="news-item__content-text">
				<p class="font1"> {{ relativeNew.NAME }} </p>
			  </div>
			  <span class="size-xs"> {{ formatDate(item.ACTIVE_FROM) }}</span>
			</router-link>
		  </li>
		</ul>
		<router-link :to="{ name: 'news' }">
		  <btn class="d-block d-sm-none text-left">
			Все новости
		  </btn>
		</router-link>
	  </v-container>
	</div>


  </section>
</template>

<style lang="scss">
.news-item {
  &__content{
	max-width: 900px;
  }
  b {
	font-weight: bold;
  }

  &__news-block {
	background-color: rgb(242, 248, 252);
  }

  ul, li {

	padding: unset;
	list-style: none;
	margin: 0 auto;
  }

  h4 {
	max-width: none;
	@include up($sm) {
	  max-width: 600px;
	}
	@include up($md) {
	  max-width: 600px;
	}
	@include up($xl) {
	  max-width: 900px;
	}
  }

  ul {
	@include up($sm) {
	  display: grid;
	  grid-template-columns: repeat(2, 1fr);
	}
	@include up($md) {
	  grid-template-columns: repeat(3, 1fr);
	}
  }

  li {
	width: 250px;
	@include up($md) {
	  width: 350px;
	}
	@include up($lg) {
	  width: 496px;
	}

	&:not(last-child) {
	  margin-bottom: 25px;
	}
  }

  &__content {
	height: 100%;
	margin-bottom: 40px;
	@include up($sm) {
	  margin-bottom: 80px;
	}
	@include up($md) {
	  margin-bottom: 60px;
	}
	@include up($lg) {
	  margin-bottom: 80px;
	}

	&-text {
	  margin: 10px  0;
	  height: 100%;
	  max-height: 90px;
	  overflow: hidden;
	  text-overflow: ellipsis;
	  @include up($sm) {
		margin-bottom: 4px;
	  }
	  @include up($md) {
		margin-bottom: 16px;
	  }

	}
  }

  &__btn {
	svg {

	  transform: rotateY(180deg);
	}

  }

  .btn {
	margin-left: unset;
	flex-direction: row-reverse;

	svg {
	  margin-left: unset !important;
	  margin-right: 16px;
	  //transform: rotateY(180deg);
	}
  }

  .swiper {
	width: 100%;
	//min-height: 500px;
	// right: -$container-padding;
	//min-width: 336px;
	@include up($md) {
	  padding-left: 5.5%;
	}
	@include up($lg) {
	  padding-left: 4%;
	}
	@include up($xl) {
	  padding-left: 8.7%;
	}
	.v-image{
	  height: 100%;
	}
  }

  .swiper-slide {
	height: 230px;
	@include up($sm) {
	  //height: 340px;
	}
	@include up($md) {
	  //height: 440px;
	}
	@include up($lg) {
	  height: 450px;
	}
  }

  .swiper-button-next {
	position: relative;
	width: 40px;
	height: 40px;

	background-image: url(@/assets/Home/home-design/next.svg);
	@include transition;

	&:hover {
	  background-image: url(@/assets/Home/home-design/next-hover.svg);
	}

	&:active {
	  background-image: url(@/assets/Home/home-design/next-hover.svg);
	}

	&:disabled {
	  background-image: url(@/assets/Home/home-design/next-dis.svg);
	}
  }

  .swiper-button-next:after {
	display: none;
  }

  .swiper-button-prev {
	margin-left: -10px;
	position: relative;
	width: 40px;
	height: 40px;
	margin-right: 30px;
	background-image: url(@/assets/Home/home-design/prev.svg);
	@include transition;

	&:hover {
	  background-image: url(@/assets/Home/home-design/prev-hover.svg);
	}

	&:active {
	  background-image: url(@/assets/Home/home-design/prev-hover.svg);
	}

	&:disabled {
	  background-image: url(@/assets/Home/home-design/prev-dis.svg);
	}
  }

  .swiper-button-prev:after {
	display: none;
  }


}
</style>

<script>
import Btn from "@/components/btn";
import lazyImage from "@/utils/lazy-image";
import Swiper, {Navigation} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default {
  components: {
	Btn,
  },
  computed: {
	item() {
	  return this.$store.state.page?.PubController?.item;
	},
	slides() {
	  return this.$store.state.page?.PubController?.item.IMAGES;
	},
	relativeNews() {
	  return this.$store.state.page?.PubController?.featPubs;
	},
	lazyImg() {
	  return lazyImage;
	},
  },
  methods :{
	openImageFullScreen(imageSrc) {
	  if (window.innerWidth < 1200) {
		return;
	  }
	  const backgroundOverlay = document.createElement('div');
	  backgroundOverlay.style.position = 'fixed';
	  backgroundOverlay.style.top = '0';
	  backgroundOverlay.style.left = '0';
	  backgroundOverlay.style.width = '100%';
	  backgroundOverlay.style.height = '100%';
	  backgroundOverlay.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
	  backgroundOverlay.style.zIndex = '9998';
	  backgroundOverlay.style.cursor = 'pointer';

	  backgroundOverlay.addEventListener('click', () => {
		fullScreenImage.style.width = '0';
		fullScreenImage.style.height = '0';
		backgroundOverlay.remove();
		setTimeout(() => {
		  fullScreenImage.remove();
		}, 300);
	  });

	  document.body.appendChild(backgroundOverlay);

	  const fullScreenImage = document.createElement('img');
	  fullScreenImage.src = imageSrc;
	  fullScreenImage.style.maxWidth = '80%';
	  fullScreenImage.style.maxHeight = '80%';
	  fullScreenImage.style.width = '0';
	  fullScreenImage.style.height = '0';
	  fullScreenImage.style.position = 'fixed';
	  fullScreenImage.style.top = '50%';
	  fullScreenImage.style.left = '50%';
	  fullScreenImage.style.objectFit = 'contain';
	  fullScreenImage.style.transform = 'translate(-50%, -50%)';
	  fullScreenImage.style.transition = 'width 0.3s, height 0.3s';
	  fullScreenImage.style.zIndex = '9999';
	  fullScreenImage.style.cursor = 'pointer';


	  fullScreenImage.addEventListener('click', () => {
		fullScreenImage.style.width = '0';
		fullScreenImage.style.height = '0';
		backgroundOverlay.remove();
		setTimeout(() => {
		  fullScreenImage.remove();
		}, 300);
	  });

	  document.body.appendChild(fullScreenImage);
	  setTimeout(() => {
		fullScreenImage.style.width = '100%';
		fullScreenImage.style.height = '100%';
	  }, 0);
	},
	formatDate(dateString) {
	  const months = [
		'января', 'февраля', 'марта',
		'апреля', 'мая', 'июня',
		'июля', 'августа', 'сентября',
		'октября', 'ноября', 'декабря'
	  ];

	  const [day, month, year] = dateString.split('.');
	  const monthIndex = parseInt(month, 10) - 1;

	  return `${parseInt(day, 10)} ${months[monthIndex]} ${year} г.`;
	}
  },
  mounted: function () {
	var swiper = new Swiper(".swiper-news-item", {
	  slidesPerView: 1,
	  spaceBetween: 10,
	  modules: [Navigation],
	  pagination: {
		el: ".swiper-pagination",
		clickable: true,
	  },
	  navigation: {
		nextEl: ".swiper-new-next",
		prevEl: ".swiper-new-prev",
	  },
	  breakpoints: {
		// 640: {
		//   slidesPerView: 1.7,
		//   spaceBetween: 20,
		// },
		744: {
		  slidesPerView: 1.7,
		  spaceBetween: 40,
		},
		1200: {
		  slidesPerView: 3,
		  spaceBetween: 50,
		},
		1720: {
		  slidesPerView: 2.5,
		  spaceBetween: 50,
		},
	  },
	});
  },
}
</script>