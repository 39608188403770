<template>
    <v-app>
        <transition name="route" mode="out-in">
            <router-view/>
        </transition>
    </v-app>
</template>

<script>
export default {
    name: 'App',

}
</script>

<style lang="scss">
#app {
    a {
        text-decoration: none;
        color: $black;
        @include transition();
    }

    a:hover,
    a:active {
        color: $primary-1;
    }

    body {
        line-height: 130%;
    }


    .route-enter-active,
    .route-leave-active {
        transition: opacity 0.3s;
    }

    .route-enter,
    .route-leave-to {
        opacity: 0;
    }

    .route-slow-enter-active,
    .route-slow-leave-active {
        transition: opacity 0.5s;
    }

    .route-slow-enter,
    .route-slow-leave-to {
        opacity: 0;
    }

    .v-slider__track-background.primary.lighten-3 {
        background-color: $mid-grey !important;
        border-color: $mid-grey !important;
    }

    .v-slider__track-fill.primary,
    .v-slider__thumb.primary {
        background-color: $primary-2 !important;
        border-color: $primary-2 !important;
    }

    .theme--light.v-btn.v-btn--has-bg {
        background-color: initial;
    }
}
</style>