<template>
  <section class="home-infrastructure">
	<v-container class=" relative z-index-2">
	  <v-row>
		<v-col cols="12" sm="7" lg="8" class="home-infrastructure__height pl-lg-0 d-none d-sm-block">
		  <div class="swiper swiper-1">
			<div class="swiper-wrapper">
			  <div class="swiper-slide relative">
				<v-img
					src="@/assets/Home/home-infrastructure/1.jpg"
					alt="Slide"
				/>
			  </div>
			  <div class="swiper-slide">
				<v-img
					src="@/assets/Home/home-infrastructure/2.jpg"
					alt="Slide"
				/>
			  </div>
			  <div class="swiper-slide">
				<v-img
					src="@/assets/Home/home-infrastructure/3.jpg"
					alt="Slide"
				/>
			  </div>
			</div>
		  </div>
		</v-col>
		<!--        <v-col cols="12" sm="5" lg="4" class="relative z-index-1 pa-0">-->

		<!--        </v-col>-->
	  </v-row>
	  <div
		  class="
              home-infrastructure__text
              z-index-1
              pt-6
              pb-4
              py-md-8
              px-md-6
            "
	  >
		<h2 class="mb-8 mb-md-15">Развитая инфраструктура</h2>
		<p class="mb-8 mb-md-10">
		  В непосредственной близости к Serebro by Avenue-Apart расположены популярные гипермаркеты и торговые центры,
		  где можно прибрести всё необходимое - от продуктов до стройматериалов. Для быстрого пополнения домашних
		  запасов подойдут «магазины у дома», работающие неподалёку от Комплекса.
		</p>

	  </div>
	  <router-link :to="{ name: 'flats' }">
		<btn class="home-infrastructure__btn d-none d-md-block"
		>Выбрать апартаменты
		</btn>
	  </router-link>
	</v-container>
	<div class="swiper swiper-2 d-sm-none">
	  <div class="swiper-wrapper">
		<div class="swiper-slide">
		  <v-img src="@/assets/Home/home-infrastructure/1.jpg" alt="Slide"/>
		</div>
		<div class="swiper-slide">
		  <v-img src="@/assets/Home/home-infrastructure/2.jpg" alt="Slide"/>
		</div>
		<div class="swiper-slide">
		  <v-img src="@/assets/Home/home-infrastructure/3.jpg" alt="Slide"/>
		</div>
	  </div>
	  <div class="swiper-button-next">
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		  <path d="M28 20L18 14.2265V25.7735L28 20ZM12 21H19V19H12V21Z" fill="white"/>
		</svg>

	  </div>
	  <div class="swiper-button-prev">
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		  <path d="M28 20L18 14.2265V25.7735L28 20ZM12 21H19V19H12V21Z" fill="white"/>
		</svg>

	  </div>
	</div>
  </section>
</template>

<script>
import Swiper, {Navigation, Mousewheel} from "swiper";
import Btn from "@/components/btn";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default {
  components: {
	Btn,
  },
  mounted: function () {
	var swiper = new Swiper(".swiper-1", {
	  direction: "vertical",
	  slidesPerView: 1,
	  spaceBetween: 50,
	  modules: [Navigation, Mousewheel],
	  mousewheel: true,
	  pagination: {
		el: ".swiper-pagination",
		clickable: true,
	  },
	  breakpoints: {
		744: {
		  slidesPerView: 1.1,
		  spaceBetween: 20,
		},
		1200: {
		  slidesPerView: 1.2,
		  spaceBetween: 40,
		},
		1740: {
		  slidesPerView: 1.1,
		  spaceBetween: 40,
		},
	  },
	});

	var swiper2 = new Swiper(".swiper-2", {
	  slidesPerView: 1,
	  spaceBetween: 10,
	  modules: [Navigation],
	  pagination: {
		el: ".swiper-pagination",
		clickable: true,
	  },
	  navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	  },
	});
  },
};
</script>

<style lang="scss">
.home-infrastructure {
  &__btn {
	@include up($md) {
	  position: absolute;
	  right: 90px;
	  bottom: 20px;
	}
	@include up($lg) {
	  right: 180px;
	  bottom: 20px;
	}

  }

  &__text {
	position: relative;
	top: 120px;
	//left: -$container-padding;
	//left: 45%;
	//transform: translateX(-50%);
	//min-width: 362px;
	font-size: 20px;

	&:before {
	  position: absolute;
	  content: "";
	  top: -20px;
	  left: -44px;
	  width: calc(100% + 50px);
	  height: calc(100% + 30px);
	  border: 1.5px solid transparent;
	  //border-image: linear-gradient(309.68deg, #FFFFFF 0%, #979EB4 84.1%);
	  border-image: linear-gradient(250deg, #FFFFFF -7%, #979EB4 94.1%);
	  border-image-slice: 1;
	  @include up($sm) {
		width: calc(100% + 10px);
		height: calc(100% + 10px);
		left: -10px;
		top: -10px;
	  }
	}

	@include up($sm) {
	  position: absolute;
	  left: 71.5%;
	  transform: translateX(-50%);
	  top: 50px;
	  //right: 10px;
	  min-width: 380px;
	}
	@include up($md) {
	  min-width: 524px;
	  top: 100px;
	  right: 40px;
	}
	@include up($lg) {
	  min-width: 624px;
	  top: 200px;
	  right: 150px;
	}
  }

  &__height {
	@include up($sm) {
	  height: 800px;
	}
	@include up($md) {
	  height: unset;
	}
  }

  .swiper {
	width: 100%;
	height: 100%;
	min-width: 360px;
	min-height: 380px;
	// overflow-x: visible;
	@include up($sm) {
	  width: 100%;
	  height: 70%;
	  //max-width: 400px;
	  margin-left: 0;
	}

	@include up($md) {
	  max-width: 750px;
	  height: 810px;
	}
	@include up($lg) {
	  max-width: 980px;
	  height: 1110px;
	}
  }

  .swiper-slide {
	//text-align: center;
	//font-size: 18px;
	//background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;

	&:before {
	  position: absolute;
	  content: "";
	  width: 100%;
	  height: 100%;
	  background: linear-gradient(360deg, rgba(255, 255, 255, 0) 28.94%, rgba(255, 255, 255, 0.8) 100%);
	  mix-blend-mode: lighten;
	  opacity: 0.8;
	  z-index: 1;
	  @include up($sm) {
		position: absolute;
		content: "";
		width: 100%;
		height: 100%;
		background: linear-gradient(90deg, rgba(255, 255, 255, 0) 35.77%, #FFFFFF 100%);
		mix-blend-mode: lighten;
		opacity: 0.8;
		z-index: 1;
	  }
	}

	@include up($sm) {
	  max-width: 550px;
	}
	@include up($md) {
	  max-width: unset;
	}
  }

  .swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
  }

  .swiper-button-next {
	//background-image: url("@/assets/Location/location-distance/slider-arrow.svg");
	width: 40px;
	height: 40px;
  }

  .swiper-button-prev {
	//background-image: url("@/assets/Location/location-distance/slider-arrow.svg");
	transform: rotate(180deg);
	width: 40px;
	height: 40px;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
	content: "";
  }

  .swiper-button-next,
  .swiper-button-prev {
	&:hover {
	  svg path {
		fill: $primary-1;
	  }
	}

	&:active {
	  background: $white;

	  svg path {
		fill: $primary-1;
	  }
	}

  }

  .swiper-button-disabled {
	background: $light-grey;
	opacity: 1;

	svg path {
	  fill: $dark-grey;
	}
  }
}
</style>