<template>
	<div class="flats-search" id="flats-search">
		<v-container>
			<v-row class="d-md-none">
				<v-col>
					<div v-if="!isMasterPlanShow">
						<h1>
							Поиск апартаментов
						</h1>
						<button @click="isMasterPlanShow = !isMasterPlanShow">
							Показать генплан
							<svg class="ml-2" width="16" height="12" viewBox="0 0 16 12" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<path d="M16 6L6 0.226497V11.7735L16 6ZM0 7H7V5H0V7Z" fill="#252E34"/>
							</svg>
						</button>
					</div>

					<div v-if="isMasterPlanShow">
						<div class="d-flex justify-center align-center mb-2">
							<!--							<svg class="flats-search__back-arrow" @click="isMasterPlanShow = !isMasterPlanShow"
															 width="16" height="12" viewBox="0 0 16 12" fill="none"
															 xmlns="http://www.w3.org/2000/svg">
															<path d="M16 6L6 0.226497V11.7735L16 6ZM0 7H7V5H0V7Z" fill="#252E34"/>
														</svg>-->
							<svg class="flats-search__back-arrow" @click="isMasterPlanShow = !isMasterPlanShow"
								 width="24" height="24" viewBox="0 0 24 24" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<g clip-path="url(#clip0_1410_9199)">
									<path
										d="M10.931 2.75026C10.7164 1.96053 10.2478 1.26336 9.59767 0.766328C8.94752 0.269291 8.15188 0 7.3335 0C6.51512 0 5.71948 0.269291 5.06933 0.766328C4.41917 1.26336 3.95064 1.96053 3.736 2.75026H0V4.75026H3.736C3.95064 5.53999 4.41917 6.23715 5.06933 6.73419C5.71948 7.23123 6.51512 7.50052 7.3335 7.50052C8.15188 7.50052 8.94752 7.23123 9.59767 6.73419C10.2478 6.23715 10.7164 5.53999 10.931 4.75026H24V2.75026H10.931ZM7.333 5.50026C6.98688 5.50026 6.64854 5.39762 6.36075 5.20533C6.07297 5.01304 5.84866 4.73973 5.71621 4.41995C5.58376 4.10018 5.5491 3.74832 5.61663 3.40885C5.68415 3.06938 5.85082 2.75756 6.09556 2.51282C6.3403 2.26808 6.65213 2.10141 6.99159 2.03388C7.33106 1.96636 7.68293 2.00102 8.0027 2.13347C8.32247 2.26592 8.59578 2.49023 8.78807 2.77801C8.98036 3.0658 9.083 3.40414 9.083 3.75026C9.08221 4.21414 8.89758 4.6588 8.56956 4.98682C8.24154 5.31484 7.79689 5.49947 7.333 5.50026V5.50026Z"
										fill="#252E34"/>
									<path
										d="M16.667 8.25C15.8495 8.25293 15.0553 8.5233 14.4058 9.01985C13.7564 9.51639 13.2872 10.2118 13.07 11H0V13H13.07C13.2843 13.7899 13.7526 14.4873 14.4027 14.9846C15.0528 15.4818 15.8485 15.7512 16.667 15.7512C17.4855 15.7512 18.2812 15.4818 18.9313 14.9846C19.5814 14.4873 20.0497 13.7899 20.264 13H24V11H20.264C20.0468 10.2118 19.5776 9.51639 18.9282 9.01985C18.2787 8.5233 17.4845 8.25293 16.667 8.25V8.25ZM16.667 13.75C16.3209 13.75 15.9825 13.6474 15.6948 13.4551C15.407 13.2628 15.1827 12.9895 15.0502 12.6697C14.9178 12.3499 14.8831 11.9981 14.9506 11.6586C15.0181 11.3191 15.1848 11.0073 15.4296 10.7626C15.6743 10.5178 15.9861 10.3511 16.3256 10.2836C16.6651 10.2161 17.0169 10.2508 17.3367 10.3832C17.6565 10.5157 17.9298 10.74 18.1221 11.0278C18.3144 11.3155 18.417 11.6539 18.417 12C18.4165 12.464 18.2319 12.9088 17.9039 13.2369C17.5758 13.5649 17.131 13.7495 16.667 13.75V13.75Z"
										fill="#252E34"/>
									<path
										d="M7.333 16.5C6.51494 16.5023 5.72013 16.7723 5.07004 17.2689C4.41995 17.7655 3.95035 18.4613 3.733 19.25H0V21.25H3.736C3.95064 22.0397 4.41917 22.7369 5.06933 23.2339C5.71948 23.731 6.51512 24.0002 7.3335 24.0002C8.15188 24.0002 8.94752 23.731 9.59767 23.2339C10.2478 22.7369 10.7164 22.0397 10.931 21.25H24V19.25H10.931C10.7137 18.4617 10.2444 17.7661 9.59476 17.2695C8.94507 16.773 8.15072 16.5027 7.333 16.5ZM7.333 22C6.98688 22 6.64854 21.8974 6.36075 21.7051C6.07297 21.5128 5.84866 21.2395 5.71621 20.9197C5.58376 20.5999 5.5491 20.2481 5.61663 19.9086C5.68415 19.5691 5.85082 19.2573 6.09556 19.0126C6.3403 18.7678 6.65213 18.6011 6.99159 18.5336C7.33106 18.4661 7.68293 18.5008 8.0027 18.6332C8.32247 18.7657 8.59578 18.99 8.78807 19.2777C8.98036 19.5655 9.083 19.9039 9.083 20.25C9.08221 20.7139 8.89758 21.1585 8.56956 21.4866C8.24154 21.8146 7.79689 21.9992 7.333 22V22Z"
										fill="#252E34"/>
								</g>
								<defs>
									<clipPath id="clip0_1410_9199">
										<rect width="24" height="24" fill="white"/>
									</clipPath>
								</defs>
							</svg>

							<span class="mr-auto">Очередь {{ stageNumber }}</span>
						</div>
						<span class="d-block text-center h2 mb-10">Этаж {{ floorMap.NAME }}</span>
					</div>

					<div v-if="!isMasterPlanShow">
						<div class="flats-search__type-btns">

							<button v-for="(type, index) of apartmentsTypes" :key="index"
									:class="{'--active':form.selectedTypes.find(el=>el===type)}"
									@click="onToggleApartmentType(type)">
								{{ type }}
							</button>
						</div>
						<div class="mb-10">
							<div class="mb-10">
								Общая площадь, кв. м
								<div class="d-flex justify-space-between mt-3 mb-2">
									<span>от {{ form.square[0] }}</span>
									<span>до {{ form.square[1] }}</span>
								</div>
								<!--                              {{ form.square }}
															  {{ minApartmentsSquares }}
															  {{ maxApartmentsSquares }}-->
								<v-range-slider
									:min="minApartmentsSquares"
									:max="maxApartmentsSquares"
									v-model="form.square"
									hide-details
									step="1"></v-range-slider>
							</div>
							<!--							<div class="mb-10">
															Цена, руб
															<div class="d-flex justify-space-between mt-3 mb-2">
																<span>от 8 120 000</span>
																<span>до 20 150 000</span>
															</div>
															<input
																type="range"
																min="8120000"
																max="20150000"
																step="100000"
																v-model="value2">
														</div>
														<div class="mb-10">
															Цена за м2, руб
															<div class="d-flex justify-space-between mt-3 mb-2">
																<span>от 210 000</span>
																<span>до 350 000</span>
															</div>
															<input
																type="range"
																min="210000"
																max="350000"
																step="10000"
																v-model="value3">
														</div>-->
							<!--							<label class="mb-10">
															<input type="checkbox">
															2-уровневые апартаменты
														</label>-->
							<!--							<div class="mb-10">
															Этаж
															<div class="d-flex justify-space-between mt-3 mb-2">
																<span>1</span>
																<span>9</span>
															</div>
															<input
																type="range"
																min="1"
																max="9"
																step="1"
																v-model="value4">
														</div>-->
							<label v-for="(option, index) in apartmentsOptions" class="mb-5 d-block" :key="index">
								<input type="checkbox" :value="option" v-model="form.selectedOptions">
								{{ option }}
							</label>
						</div>
						<!--<button
							v-on:click="isHidden = !isHidden"
							class="flats-search__hide mb-10">
							<v-img class="mr-4"
								   :src="this.buttonIcon"/>
							{{ this.buttonText }}
						</button>-->


						<btn-form class="mb-10">{{ resultsCountText }}</btn-form>
						<button @click="onClearFilter">
							Сбросить фильтры
						</button>
					</div>


				</v-col>
			</v-row>
			<v-row class="d-flex">
				<v-col class="order-2 order-md-1" cols="12" md="3" ref="scrollHere">
					<!--					<div class="d-flex flex-column">-->
					<!--						<span class="d-block mb-3">Очередь {{ item.queue }}</span>-->
					<!--						<span class="flats-search__step">Этаж {{ item.floor }}</span>-->
					<!--					</div>-->
					<div v-if="selectedFlat"
						 class="flats-search__apart mt-10"
					>
						<div>
							<div v-if="selectedFlat.SOLD" class="size-xl mb-5">Продано</div>
							<div v-if="selectedFlat.SALE_START" class="size-xl mb-5">Старт продаж в 2024 г.</div>
							<div v-if="selectedFlat.IS_BOOKED" class="size-xl mb-5">Бронь</div>
							<div class="size-xl mb-5">{{ selectedFlat.TYPE }} {{ selectedFlat.NAME }}</div>
							<div class="size-sm mb-5">Общая площадь <span class="ml-10">{{
									selectedFlat.SQUARE
								}} м<sup>2</sup></span></div>
							<div v-for="(option, index) in selectedFlat.OPTIONS"
								 :key="index"
								 class="size-sm color-dark-gray mb-1">{{ option }}
							</div>
							<div class="size-sm color-dark-gray mb-1">{{ selectedFlat.TYPE }}</div>
							<div class="size-sm color-dark-gray mb-0">Высота потолка — {{ apartmentHeight }} м</div>

							<div v-if="selectedFlat.PRICE" class="flats-search__apart-price mt-5 size-sm">
								<div class="d-flex justify-space-between color-black">
									<span>Цена за м<sup>2</sup></span>
									<span>{{ selectedFlat.PRICE_FOR_M | nf }}</span>
								</div>
								<div class="d-flex justify-space-between">
									<span>Цена</span>
									<span>{{ selectedFlat.PRICE | nf }}</span>
								</div>

							</div>

							<v-img class="mb-6 mt-6 d-md-none" v-if="this.selectedFlat.PLAN !== null"
								   :src="URL + imgPlan()" contain/>
							<v-img class="d-sm-none" v-if="this.selectedFlat.PLAN !== null"
								   :src="URL + imgPlan2()" contain/>

							<div class="flats-search__visual mt-10 d-md-none"
								 v-if="this.selectedFlat.VISUAL">

								<div class="swiper relative mySwiperFlats">
									<div class="swiper-wrapper" ref="swiperWrapper">
										<div class="swiper-slide" v-for="(item, index) of selectedVisual" :key="index">
											<v-img :src="URL + item"/>
										</div>
									</div>
									<div class="d-flex justify-center mt-12">
										<div class=" swiper-button-prev swiper-new-prev" @click="prevSlide"></div>
										<div class=" swiper-button-next swiper-new-next" @click="nextSlide"></div>
									</div>
								</div>


							</div>

							<btn class="d-none d-md-block" @click.native="flatCard = true">Карточка
								помещения
							</btn>
						</div>
						<div class="flats-search__apart-btns">
							<popup title="Записаться на просмотр" class="mb-4">Записаться на просмотр
							</popup>
							<popup
								v-if="stageNumber === '2' && (selectedFloor === '3' || selectedFloor === '4' || selectedFloor === '5')"
								heading="true"
								title="Узнать цену">Узнать цену
							</popup>
							<popup v-else title="Задать вопрос">Задать вопрос</popup>
						</div>
					</div>
				</v-col>

				<v-dialog v-if="isWideScreen" v-model="flatCard"
						  max-width="1548"
				>
					<flats-search-popup @flatCard="flatCard = false"
										:item="selectedFlat"/>
				</v-dialog>

				<v-col v-show="isMasterPlanShow" class="flats-search__floor order-1 order-md-2" md="6">
					<v-img v-if="isFlatsShow" :src="floorMap.DETAIL_PICTURE" class="relative" contain>
						<div class="flats-search__floor-flats" v-html="floorMap.SVG_CODE">

						</div>

					</v-img>
					<span class="flats-search__decor">Серебристый бульвар</span>
				</v-col>
				<v-col class="d-none d-md-block order-3" md="2">
					<v-row class="relative">
						<v-col md="6">
							<!--							<div class="flats-search__type-btns">
															<span>
																{{ Object.keys(flatsType)[0] }}
															</span>
														</div>-->
						</v-col>
						<v-col md="6">
							<div class="flats-search__aside d-flex justify-space-between text-center">
								<div class="d-flex flex-column justify-space-between">
									<span class="size-xs">1 секция</span>
									<button class="flats-search__steps flats-search__steps-first"
											v-for="(item, index) of queueTwo"
											:key="index"
											:class="{'active': isActive(item)}"
											@click="onBtnClick(item)"
									>
										{{ queueTwo.length - index }}
									</button>
								</div>
								<div class="flats-search__aside-text font-sm">
									<i class="text-line"></i>
									<span>этажи</span>
									<i class="text-line --2"></i>
								</div>
								<div class="d-flex flex-column justify-space-between">
									<span class="size-xs">2 секция</span>
									<button class="flats-search__steps flats-search__steps-second"
											v-for="(item, index) of queueOne"
											:key="index"
											:class="{'active': isActive(item)}"
											@click="onBtnClick(item)"
									>
										{{ queueOne.length - index }}
									</button>
								</div>
							</div>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-container>

		<div class="flats-search__flat-status" id="flats-search__flat-status"></div>
	</div>
</template>

<script>
import Btn from "@/components/btn";
import BtnForm from "@/components/btn-form";
import Popup from "@/components/popup";
import FlatsSearchPopup from "@/components/Flats/flats-search-popup";
import {nf, end} from "@/utils/string";
import config from "@/config";
import Swiper, {Navigation} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

let swiper;

export default {
	name: 'flats-search',
	components: {FlatsSearchPopup, BtnForm, Btn, Popup},
	props: {
		item: Object,
	},
	data() {
		return {
			currentSlide: 0,
			isHidden: true,
			buttonText: 'Скрыть расширенный фильтр',
			buttonIcon: require('@/assets/Howtobuy/howtobuy-calc/hide.svg'),
			value: 68.35,
			value2: 17500000,
			value3: 350000,
			value4: 9,
			buttons: [
				{name: 'Апартаменты'},
				{name: '2-уровневые студии'},
				{name: 'Кладовые'}
			],

			form: {
				selectedTypes: [],
				square: [this.minApartmentsSquares, this.maxApartmentsSquares],
				selectedOptions: [],
			},
			selectedFlat: null,
			flatCard: false,
			flats: null,	// todo rename to show that it is an HTML elements array
			isFlatsShow: true,
			isMasterPlanShow: true,
			URL: config.API_SERVER_URI
		}
	},
	watch: {
		isHidden() {
			if (this.isHidden) {
				this.buttonText = "Скрыть расширенный фильтр";
				this.buttonIcon = require('@/assets/Howtobuy/howtobuy-calc/hide.svg')
			} else if (!this.isHidden) {
				this.buttonText = "Расширенный поиск";
				this.buttonIcon = require('@/assets/Howtobuy/howtobuy-calc/show.svg')
			}
		},
		filteredApartments() {
			this.$emit('filteredFlats', this.filteredApartments);
		},
		/*'$route.params'(e) {
			this.initApartments(e.id);
			setTimeout(() => {
				this.initSvgMap();
			}, 200)
		}*/
	},
	computed: {
		isWideScreen() {
			return window.innerWidth > 1200;
		},
		resultsCountText() {
			if (this.filteredApartments.length)
				return end(this.filteredApartments.length,
					"Найден " + this.filteredApartments.length + " объект",
					"Найдено " + this.filteredApartments.length + " объекта",
					"Найдено " + this.filteredApartments.length + " объектов");
			return 'К сожалению, такие объекты не найдены';
		},
		queueOne() {
			return this.$store.state.page?.LevelItemController?.levels.slice(0, 9).reverse();
		},
		queueTwo() {
			return this.$store.state.page?.LevelItemController?.levels.slice(9, 15).reverse();
		},
		floorMap() {
			return this.$store.state.page.LevelItemController?.item;
		},
		apartments() {
			return this.$store.state.page.LevelItemController?.appartments;
		},
		flatsType() {
			return this.$store.state.page.LevelItemController?.levels.find(el => el.ID === this.$route.params.id).countAppartments
		},
		apartmentHeight() {
			if (this.$store.state.page?.LevelItemController?.item.STAGE === '37') return '4.6'
			else return '2.9';
		},
		stageNumber() {
			if (this.$store.state.page?.LevelItemController?.item.STAGE === '36') return '1'
			else return '2';
		},
		isWindowXS() {
			return this.$store.getters.isWindowXS;
		},
		plan() {
			return this.$store.state.page?.LevelItemController?.plans;
		},
		visuals() {
			return this.$store.state.page?.LevelItemController?.visuals;
		},
		selectedVisual() {
			if (this.selectedFlat.VISUAL) return this.visuals.find(el => el.ID === this.selectedFlat.VISUAL).PHOTOS;
		},
		selectedFloor() {
			return this.$store.state.page?.LevelItemController?.item.NAME;
		},

		/**
		 * Returns all available apartment types found in response from backend.
		 * @returns {unknown}
		 */
		apartmentsTypes() {
			return this.$store.state.page?.LevelItemController?.appartments.reduce((acc, el) => {
				if (!acc.includes(el.TYPE))
					acc.push(el.TYPE);
				return acc;
			}, []);
		},
		apartmentsSquares() {
			return this.$store.state.page?.LevelItemController?.appartments.reduce((acc, el) => {
				acc.push(parseFloat(el.SQUARE.replaceAll(',', '.')));
				return acc;
			}, []);
		},
		minApartmentsSquares() {
			console.log('Min apartments squares: ' + Math.floor(Math.min.apply(null, this.apartmentsSquares)));
			return Math.floor(Math.min.apply(null, this.apartmentsSquares));
		},
		maxApartmentsSquares() {
			console.log('Max apartments squares: ' + Math.ceil(Math.max.apply(null, this.apartmentsSquares)));
			return Math.ceil(Math.max.apply(null, this.apartmentsSquares));
		},
		/**
		 * Returns all available apartment options found in response from backend.
		 * @returns {unknown}
		 */
		apartmentsOptions() {
			return this.$store.state.page?.LevelItemController?.appartments?.reduce((acc, el) => {
				if (el.OPTIONS !== 'undefined' && el.OPTIONS !== null) {
					Object.values(el.OPTIONS).forEach((option) => {
						if (!acc.includes(option))
							acc.push(option);
					});
				}
				return acc;
			}, []);
		},
		filteredApartments() {
			let apartments = this.$store.state.page?.LevelItemController?.appartments;

			// 1. filter by type
			if (this.form.selectedTypes?.length > 0) {
				const countBefore = apartments.length;
				apartments = apartments.filter((el) => !!el.TYPE
					&& this.form.selectedTypes.find(o => el.TYPE === o)
				);
				const countAfter = apartments.length;
				console.log("Filtered by type: " + countBefore + " > " + countAfter);
			}

			// 2. filter by square
			if (this.form.square[0] > 0 && this.form.square[1] > 0) {
				apartments = apartments.filter((el) => !!el.SQUARE
					&& (parseFloat(el.SQUARE.replace(',', '.')) >= this.form.square[0]
						&& parseFloat(el.SQUARE.replace(',', '.')) <= this.form.square[1])
				);
			}

			// 3. filter by options
			if (this.form.selectedOptions?.length > 0) {
				const countBefore = apartments.length;
				apartments = apartments.filter(el => !!el.OPTIONS
					&& this.form.selectedOptions.find(o => Object.values(el.OPTIONS).includes(o))
				);
				const countAfter = apartments.length;
				console.log("Filtered by options: " + countBefore + " > " + countAfter);
			}

			return apartments;
		},

		soldApartments() {
			return this.apartments.filter(el => el.SOLD === 'Y');
		},
		nonReadyApartments() {
			return this.apartments.filter(el => el.SALE_START === 'Y');
		},
		bookedApartments() {
			return this.apartments.filter(el => el.IS_BOOKED === true);
		}
	},
	methods: {
		nextSlide() {
			const wrapper = this.$refs.swiperWrapper;
			const slides = wrapper.querySelectorAll('.swiper-slide');
			this.currentSlide = (this.currentSlide + 1) % slides.length;
			wrapper.style.transform = `translateX(-${this.currentSlide * 100}%)`;
		},
		prevSlide() {
			const wrapper = this.$refs.swiperWrapper;
			const slides = wrapper.querySelectorAll('.swiper-slide');
			this.currentSlide = (this.currentSlide - 1 + slides.length) % slides.length;
			wrapper.style.transform = `translateX(-${this.currentSlide * 100}%)`;
		},
		initApartments(rect) {
			this.selectedFlat = this.apartments.find(el => el.ID === rect.id);
		},
		imgPlan() {
			if (this.selectedFlat.PLAN) return this.plan.find(el => el.ID === this.selectedFlat.PLAN).IMAGES[0]
			else return;
		},
		imgPlan2() {
			if (this.selectedFlat.PLAN) return this.plan.find(el => el.ID === this.selectedFlat.PLAN).IMAGES[1]
			else return;
		},
		applyListenersOnSVGRect(apartment, svgRect) {
			const onClick = (e) => {
				this.onFlatSVGRectClick(apartment, svgRect, e);
			}
			svgRect.removeEventListener("click", onClick);
			svgRect.addEventListener("click", onClick);

			const onMouseOver = (e) => {
				this.onFlatSVGRectMouseOver(apartment, svgRect, e);
			}
			svgRect.removeEventListener("mouseover", onMouseOver);
			svgRect.addEventListener("mouseover", onMouseOver);
		},
		initSvgMap() {
			const img = document.getElementById("flats-svg");
			const svgRects = [...img.childNodes];

			this.flats = svgRects;

			svgRects.forEach(svgRect => {
				const apartment = this.apartments.find(el => el.ID === svgRect.id);
				if (!apartment) return;

				this.applyListenersOnSVGRect(apartment, svgRect);

				const isSold = !!this.soldApartments.find(el => el.ID === svgRect.id);
				if (isSold) {
					svgRect.classList.add('sold');
				}

				const isNotReadyFlat = !!this.nonReadyApartments.find(el => el.ID === svgRect.id);
				if (isNotReadyFlat) {
					svgRect.classList.add('not-ready');
				}

				const isBooked = !!this.bookedApartments.find(el => el.ID === svgRect.id);
				if (isBooked) {
					svgRect.classList.add('booked');
				}
			});


		},

		// todo rename
		flat(rect) {
			this.clearFilter();

			this.initApartments(rect);

			const soldFlat = this.soldApartments.find(el => el.ID === rect.id);
			const notReadyFlat = this.nonReadyApartments.find(el => el.ID === rect.id);
			const isBooked = this.bookedApartments.find(el => el.ID === rect.id);

			rect.classList.add('active');
			if (!this.isWindowXS && !soldFlat && !notReadyFlat && !isBooked) {
				this.flatCard = true;
			}
			this.scrollToFlat();


		},
		isActive(item) {
			if (item.ID === this.$route.params.id) return true;
		},
		clearFilter() {
			this.selectedFlat = null;
			this.apartments.forEach(item => {
				this.flats.find(flat => {
					if (item.ID === flat.id) {
						flat.classList.remove('activeFlat');
						flat.classList.remove('active');
					}
				})
			});

		},
		filteredFlats(filter) {
			this.clearFilter();

			const type = this.apartments.filter(flat => flat.type === filter.name);
			const flat = this.flats.filter(flat => flat.id);

			type.forEach(t => {
				flat.find(f => {
					if (t.id === f.id) f.classList.add('activeFlat');
				})
			})

			this.$emit('filteredFlats', type);
		},
		apartmentStatus(apartment) {
			if (apartment?.SOLD === "Y") return "Продано";
			else if (apartment?.SALE_START === "Y") return "Старт продаж в 2024 г.";
			else if (apartment?.IS_BOOKED === true) return "Бронь";
			return "В продаже";
		},
		onFlatSVGRectClick(apartment, svgRect) {
			this.flat(svgRect);
			this.$emit('hideFlats');
		},
		onFlatSVGRectMouseOver(apartment, svgRect) {
			const statusDiv = document.getElementById("flats-search__flat-status");
			if (!statusDiv) return;

			const section = document.getElementById("flats-search");
			const sectionRect = section.getBoundingClientRect();

			const rect = svgRect.getBoundingClientRect();
			statusDiv.style.left = rect.left + rect.width / 2 + "px";
			statusDiv.style.top = rect.top + rect.height / 2 - sectionRect.top + "px";

			statusDiv.innerText = this.apartmentStatus(apartment);
			statusDiv.style.display = "block";
		},
		onBtnClick(item) {
			this.$router.push({name: 'flats-choice', params: {id: item.ID}});

			this.isFlatsShow = false;
			setTimeout(() => {
				this.isFlatsShow = true;
			}, 100)
		},
		scrollToFlat() {
			if (this.isWindowXS) {
				this.$refs.scrollHere.scrollIntoView({
					behavior: 'smooth',
				});
			}
			console.log("VISUAL", this.selectedFlat);
			console.log("selectedVisual", this.selectedVisual);
			console.log("selectedVisualArr", this.selectedVisualArr);
		},
		onToggleApartmentType(type) {
			console.log("Type clicked", type);
			const existingIndex = this.form.selectedTypes.findIndex(el => el === type);
			if (existingIndex >= 0) this.form.selectedTypes.splice(existingIndex, 1);
			else this.form.selectedTypes.push(type);
		},
		// todo: проверить разницу с clearFilter
		onClearFilter() {
			this.form.selectedTypes.splice(0, this.form.selectedTypes.length);
			this.form.selectedOptions.splice(0, this.form.selectedOptions.length);
			this.form.square = [this.minApartmentsSquares, this.maxApartmentsSquares];
		},
		/*
				initSwiper() {
					swiper = new Swiper('.mySwiperVisuals', {
						slidesPerView: 1,
						spaceBetween: 10,
						freeMode: true,
						modules: [Navigation],
						pagination: {
							el: ".swiper-pagination",
							clickable: true,
						},
						navigation: {
							nextEl: ".swiper-new-next",
							prevEl: ".swiper-new-prev",
						},
					});
				},
		*/


	},
	mounted() {
		setTimeout(() => {
			this.initSvgMap();

		}, 300);
		// this.initSwiper();
	},
	// todo find out what this does
	updated() {
		console.log("flats-search updated");
		setTimeout(() => {
			this.initSvgMap();
		}, 300);
	}
}
</script>

<style lang="scss">
.flats-search {
	position: relative;
	background: $sky-blue;

	.swiper {
		width: 100%;
		//min-height: 500px;
		// right: -$container-padding;
		//min-width: 336px;
		@include up($md) {
			padding-left: 5.5%;
		}
		@include up($lg) {
			padding-left: 4%;
		}
		@include up($xl) {
			padding-left: 8.7%;
		}

		.v-image {
			height: 100%;
		}
	}

	.swiper-slide {
		height: 230px;
		@include up($sm) {
			//height: 340px;
		}
		@include up($md) {
			//height: 440px;
		}
		@include up($lg) {
			height: 450px;
		}
	}

	.swiper-button-next {
		position: relative;
		width: 40px;
		height: 40px;

		background-image: url(@/assets/Home/home-design/next.svg);
		@include transition;

		&:hover {
			background-image: url(@/assets/Home/home-design/next-hover.svg);
		}

		&:active {
			background-image: url(@/assets/Home/home-design/next-hover.svg);
		}

		&:disabled {
			background-image: url(@/assets/Home/home-design/next-dis.svg);
		}
	}

	.swiper-button-next:after {
		display: none;
	}

	.swiper-button-prev {
		margin-left: -10px;
		position: relative;
		width: 40px;
		height: 40px;
		margin-right: 30px;
		background-image: url(@/assets/Home/home-design/prev.svg);
		@include transition;

		&:hover {
			background-image: url(@/assets/Home/home-design/prev-hover.svg);
		}

		&:active {
			background-image: url(@/assets/Home/home-design/prev-hover.svg);
		}

		&:disabled {
			background-image: url(@/assets/Home/home-design/prev-dis.svg);
		}
	}

	.swiper-button-prev:after {
		display: none;
	}

	.btn-form {
		background: #dee9ec;
		border-color: #dee9ec;
		color: #000;

		&:hover {
			background: #dee9ec;
		}
	}

	h1 {
		color: $black;
		margin-bottom: 64px;
	}

	&__back-arrow {
		margin-right: calc(50% - 64px);
		transform: rotate(180deg);
		cursor: pointer;
	}

	&__flat-status {
		position: absolute;
		z-index: 2;
		padding: 4px 10px;
		border-radius: 2px;
		background: #272E33;
		color: $white;
		font-size: 12px;
		display: none;
		transform: translateX(-50%) translateY(-50%);
	}

	&__floor {
		position: relative;

		@include up($md) {
			min-height: 650px;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}

		@include up($xl) {
			min-height: 850px;
		}

		&-flats {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			@include up($md) {
				top: 35px;
			}

			svg {
				rect[id],
				path[id] {
					cursor: pointer;

					&.activeFlat {
						fill-opacity: 0.5;
						stroke-width: 2;
						fill: transparent;
					}

					&:hover,
					&.active {
						@include transition();
						fill-opacity: 0.5;
						stroke-width: 2;
					}

					&.sold {
						fill: #ed1c24;
						fill-opacity: 0.3;
						stroke: #ed1c24;
					}

					&.not-ready {
						fill: #fbedca;
						fill-opacity: 0.5;
						stroke: #fbedca;
					}

					&.booked {
						fill: #6d1448;
						fill-opacity: 0.5;
						stroke: #6d1448;
					}

				}
			}

		}
	}

	&__type-btns {
		margin: 40px 0;
		display: flex;
		flex-wrap: wrap;
		gap: 16px;

		& button {
			padding: 8px 12px;
			background-color: $white;
			@include transition();

			@include up($md) {
				padding: 0;
				background-color: inherit;
				text-transform: lowercase;
			}

			&:hover,
			&:active {
				color: $primary-1;
			}

			&:disabled {
				color: $dark-grey;
			}

			&.--active {
				background-color: $primary-1;
				color: $white;
			}
		}

		@include up($md) {
			position: absolute;
			left: -55px;
			top: 12px;
			flex-direction: column;
			align-items: flex-start;
			margin: 0;
		}
	}

	&__hide {
		display: flex;
		text-align: left;
		align-items: flex-start;
	}

	&__step {
		font-family: $header-font-family;
		font-size: 60px;
		line-height: 100%;
		letter-spacing: 0.02em;
	}

	&__aside {
		//position: absolute;
		//width: 128px;
		height: 549px;
		//top: 25%;
		//right: 32px;

		&-text {
			display: flex;
			align-items: center;
			position: relative;

			span {
				display: block;
				transform: rotate(-90deg);
			}

			.text-line {
				position: absolute;
				width: 1px;
				height: 43%;
				background: $mid-grey;
				top: 0;
				right: calc(50% - 0.5px);

				&.--2 {
					bottom: 0;
					top: auto;
				}
			}
		}
	}

	&__steps {
		background-color: $white;

		&.active {
			background: $primary-2;
			color: $white;
		}

		&-first {
			padding: 20px 14px;
		}

		&-second {
			padding: 7px 14px;
		}

		@include transition();

		&:hover {
			background: $primary-2;
			color: $white;
		}

		&:active {
			background: $primary-1;
			color: $white;
		}

		&:disabled {
			background: $light-grey;
			color: $mid-grey;
		}
	}

	&__apart {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		&-price {
			color: #252E34;
		}

		.btn {
			margin-left: 0;
			margin-top: 60px;
		}

		.btn-form {
			max-width: 100%;
		}

		&-btns {
			margin-top: 40px;
			margin-bottom: 64px;

			@include up($md) {
				margin-top: 130px;
				margin-bottom: unset;
			}
		}

		& .v-image {
			max-width: 250px;
			max-height: 350px;
			margin: 0 auto;
		}
	}

	&__decor {
		text-align: center;
		display: block;
		position: relative;
		margin-top: 20px;

		&::before,
		&::after {
			position: absolute;
			content: "";
			width: 15%;
			bottom: 13px;
			height: 1px;
			border-top: 1px solid #C1CFD8;

			@include up($md) {
				width: 30%;
			}

			@include up($lg) {
				width: 35%;
			}
		}

		&::before {
			left: 0;
		}

		&::after {
			right: 0;
		}
	}

	input[type='range'] {
		width: 100%;
		height: 10px;
		//overflow: hidden;
		cursor: pointer;
		position: relative;
		@include up($md) {
			max-width: 630px;
		}

		&:before {
			position: absolute;
			content: "";
			left: 0;
			top: 0;
			width: 10px;
			height: 10px;
			background: #6DBFF5;
			border-radius: 50%;
		}
	}

	.popup {
		.v-btn {
			max-width: unset;
		}
	}

	input[type='range'],
	input[type='range']::-webkit-slider-runnable-track,
	input[type='range']::-webkit-slider-thumb {
		-webkit-appearance: none;
	}

	input[type='range']::-webkit-slider-runnable-track {
		width: 100%;
		height: 2px;
		background: #6DBFF5;
		border-radius: 20px;
		position: relative;
	}

	input[type='range']::-webkit-slider-thumb {
		height: 10px;
		width: 10px;
		margin-top: -4px;
		background: #6DBFF5;
		border-radius: 50%;
		//border: 2px solid white;
	}


}

</style>