<template>
	<section class="home-news">
		<v-container>
			<div class="d-sm-flex align-baseline justify-space-between">
				<h2 class="mb-8 mb-md-15">События и новости</h2>
				<router-link :to="{ name: 'news' }">
					<btn class="home-news__btn d-none d-sm-block">
						Все новости
					</btn>
				</router-link>
			</div>
			<ul>
				<li v-for="(item, index) of news" :key="index">
					<router-link :to="'news/' + item.CODE">
						<v-img :src="item.PREVIEW_PICTURE"/>
						<div class="home-news__content-text">
							<p class="font1"> {{ item.NAME }} </p>
						</div>
						<span class="size-xs"> {{ formatDate(item.ACTIVE_FROM) }}</span>
					</router-link>
				</li>
			</ul>
			<router-link :to="{ name: 'news' }">
				<btn class="mt-4 home-news__btn  d-block d-sm-none">
					Все новости
				</btn>
			</router-link>

		</v-container>
	</section>
</template>

<script>
import Btn from "@/components/btn";

export default {
	components: {
		Btn,
	},
	computed: {
		news() {
			return this.$store.state.page?.HomeController?.news.slice(0, 3);
		}
	},
	methods: {
		formatDate(dateString) {
			const months = [
				'января', 'февраля', 'марта',
				'апреля', 'мая', 'июня',
				'июля', 'августа', 'сентября',
				'октября', 'ноября', 'декабря'
			];

			const [day, month, year] = dateString.split('.');
			const monthIndex = parseInt(month, 10) - 1;

			return `${parseInt(day, 10)} ${months[monthIndex]} ${year} г.`;
		}
	}
}
</script>

<style lang="scss">
.home-news {
	ul, li {
		list-style: none;
		padding: unset;
	}

	ul {
		max-width: 320px;
		margin: 0 auto;
		@include up($md) {
			max-width: unset;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 30px;
		}
	}

	li {
		@include up($md) {
			width: 350px;
		}
	}

	a {
		display: table-caption;
	}

	.v-image {
		max-width: 312px;
		max-height: 190px;
		margin-bottom: 18px;

		@include up($md) {
			max-width: 350px;
			//height: auto;
			//max-height: 200px;
		}
		@include up($lg) {
			max-width: 496px;
			max-height: 260px;
		}
	}

	p {
		margin-bottom: 10px;
	}

	&__btn {
		background-color: transparent;
		text-wrap: nowrap;
		min-width: 155px;
	}

	&__content {
		height: 100%;
		margin-bottom: 40px;
		@include up($sm) {
			margin-bottom: 80px;
		}
		@include up($md) {
			margin-bottom: 60px;
		}
		@include up($lg) {
			margin-bottom: 80px;
		}

		&-text {
			margin-bottom: 10px;
			height: 100%;
			max-height: 90px;
			overflow: hidden;
			text-overflow: ellipsis;
			@include up($sm) {
				margin-bottom: 4px;
			}
			@include up($md) {
				margin-bottom: 16px;
			}

		}
	}
}
</style>