<template>
  <section class="home-apartments relative">
	<v-container class="relative pb-3 pb-sm-6 pr-0">
	  <h2 class="home-apartments__white mt-5 mb-10 relative z-index-1">
		Апартаменты
	  </h2>
	  <div class="swiper relative mySwiperApartments">
		<div class="swiper-wrapper">
		  <div v-for="imageIndex in 12" :key="imageIndex" class="swiper-slide">
			<v-img
				:src="require(`@/assets/Home/home-apartments/${imageIndex}.jpg`)"
				alt="Slide"
				class="slider-image"
			/>
		  </div>
		</div>
	  </div>
	  <div class="swiper-apartments-next swiper-button-next"></div>
	  <div class="swiper-apartments-prev swiper-button-prev"></div>
	</v-container>
	<div class="home-apartments__bg"></div>
  </section>
</template>
<script>


import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Swiper, {Navigation} from "swiper";

export default {
  computed: {

	},


mounted: function () {
  var swiper = new Swiper(".mySwiperApartments", {
	slidesPerView: 1,
	spaceBetween: 10,
	modules: [Navigation],
	pagination: {
	  el: ".swiper-pagination",
	  clickable: true,
	},
	navigation: {
	  nextEl: ".swiper-apartments-next",
	  prevEl: ".swiper-apartments-prev",
	},
	breakpoints: {

	  744: {
		slidesPerView: 1.7,
		spaceBetween: 40,
	  },
	  1200: {
		slidesPerView: 2.5,
		spaceBetween: 50,
	  },
	},
  });
},
};
</script>
<style lang="scss">
.home-apartments {


  &__bg {
	position: absolute;
	background: url(@/assets/Home/home-design/bg.jpg);
	background-blend-mode: screen;
	top: 0;
	height: 350px;
	width: 100%;
	background-position: left top;
	background-repeat: no-repeat;
	background-size: cover;

	@include up($sm) {
	  background-position: center top;
	}
	@include up($lg) {
	  height: 600px;
	}
  }

  &__border {
	position: absolute;
	top: 260px;
	left: 60px;
	// TODO gradient
	border: 1px solid $dark-grey;
	width: 632px;
	height: 680px;
  }
  &__white {
	color: $white;
  }

  .swiper {
	width: 100%;
	height: 100%;
	min-width: 336px;
  }

  .swiper-slide {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 632px;

	@include up($sm) {
	  min-width: 372px;
	}
  }

  .swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
  }
  .swiper-button-next {
	width: 40px;
	height: 40px;
	background-image: url(@/assets/Home/home-design/next.svg);
	left: 80px;
	top: 105%;
	@include transition;
	&:hover {
	  background-image: url(@/assets/Home/home-design/next-hover.svg);
	}
	&:active {
	  background-image: url(@/assets/Home/home-design/next-hover.svg);
	}
	&:disabled {
	  background-image: url(@/assets/Home/home-design/next-dis.svg);
	}
	@include up ($lg) {
	  left: 115px;
	}
  }
  .swiper-button-next:after {
	display: none;
  }

  .swiper-button-prev {
	width: 40px;
	height: 40px;
	background-image: url(@/assets/Home/home-design/prev.svg);
	left: 30px;
	top: 105%;
	@include transition;
	&:hover {
	  background-image: url(@/assets/Home/home-design/prev-hover.svg);
	}
	&:active {
	  background-image: url(@/assets/Home/home-design/prev-hover.svg);
	}
	&:disabled {
	  background-image: url(@/assets/Home/home-design/prev-dis.svg);
	}
	@include up ($lg) {
	  left: 65px;
	}
  }
  .swiper-button-prev:after {
	display: none;
  }
  .mySwiperApartments {
	width: 100%;
	height: 447px;
  }
  .slider-image {
	width: 100%;
	height: 100%;
  }

}
</style>