export const items = {
	queueOne: [
		{id: 'o26', queue: 'Очередь 2', floor: '6'},
		{id: 'o25', queue: 'Очередь 2', floor: '5'},
		{id: 'o24', queue: 'Очередь 2', floor: '4'},
		{id: 'o23', queue: 'Очередь 2', floor: '3'},
		{id: 'o22', queue: 'Очередь 2', floor: '2'},
		{id: 'o21', queue: 'Очередь 2', floor: '1'},
	],
	queueTwo: [
		{id: 'o19', queue: 'Очередь 1', floor: '9'},
		{id: 'o18', queue: 'Очередь 1', floor: '8'},
		{id: 'o17', queue: 'Очередь 1', floor: '7'},
		{id: 'o16', queue: 'Очередь 1', floor: '6'},
		{id: 'o15', queue: 'Очередь 1', floor: '5'},
		{id: 'o14', queue: 'Очередь 1', floor: '4'},
		{id: 'o13', queue: 'Очередь 1', floor: '3'},
		{id: 'o12', queue: 'Очередь 1', floor: '2'},
		{id: 'o11', queue: 'Очередь 1', floor: '1'},
	],
}

//38 id пока нет
export const map = {
	svg: '<rect fill="#D9D9D9" fill-opacity="0"/>' + '<path id="38" d="M875.924 809.346L752 807.363V890.257L875.924 897L1314 880.342V803L875.924 809.346Z" fill="#6DBFF5" fill-opacity="0"/>\n' +
		'<path id="39" d="M875.924 719L752 722.983V809.407L875.924 811L1314 805.424V730.55L875.924 719Z" fill="#6DBFF5" fill-opacity="0"/>\n' +
		'<path id="40" d="M875.924 627L752 637V723.4L875.924 719.4L1314 731V653.8L875.924 627Z" fill="#6DBFF5" fill-opacity="0"/>\n' +
		'<path id="41" d="M875.924 536L752 552.181V637.425L875.924 627.559L1314 654V579.806L875.924 536Z" fill="#6DBFF5" fill-opacity="0"/>\n' +
		'<path id="42" d="M875.924 446L752 467.942V552.573L875.924 536.509L1314 580V505.947L875.924 446Z" fill="#6DBFF5" fill-opacity="0"/>\n' +
		'<path id="43" d="M875.924 354L752 382.239V466.956L875.924 444.992L1314 505V430.481L875.924 354Z" fill="#6DBFF5" fill-opacity="0"/>\n' +
		'<path id="44" d="M875.924 263L752 297.31L752.395 382.493L875.924 354.099L1314 431V356.465L875.924 263Z" fill="#6DBFF5" fill-opacity="0"/>\n' +
		'<path id="45" d="M875.924 171L752 211.538V297.385L875.924 262.808L1314 357V281.487L875.924 171Z" fill="#6DBFF5" fill-opacity="0"/>\n' +
		'<path id="46" d="M875.924 81L752 126.718V212.635L875.924 172.435L1314 282V206.707L875.924 81Z" fill="#6DBFF5" fill-opacity="0"/>\n' +
		'<path id="47" d="M745 738.581V866.165C729.93 866.165 677.977 868.21 677.977 868.21C677.977 868.21 650.219 869 622.852 869C602.805 869 568.707 866.982 540.834 865.332C527.351 864.534 515.324 863.823 507.049 863.467C483.736 862.466 447.794 860.246 412.951 858.095C379.039 856.001 346.17 853.971 327 853.192V742.533C376.47 742.533 425.914 741.149 475.352 739.766L475.356 739.766C524.784 738.383 574.207 737 623.645 737C651.012 737 677.977 738.186 677.977 738.186C677.977 738.186 729.93 738.581 745 738.581Z" fill="#6DBFF5" fill-opacity="0"/>\n' +
		'<path id="48" d="M745 619.346C729.93 618.951 677.977 616.185 677.977 616.185C677.977 616.185 650.219 615 622.852 615C607.331 615 582.56 617.368 559.87 619.538C553.254 620.17 546.815 620.786 540.834 621.321C515.964 624.403 487.051 626.936 462.192 629.115C441.326 630.943 423.316 632.521 412.951 633.963C374.987 637.914 348.019 640.679 327 641.469V743C376.472 743 425.917 741.617 475.356 740.234C524.784 738.852 574.207 737.469 623.645 737.469C651.012 737.469 677.977 738.654 677.977 738.654C677.977 738.654 729.93 739.049 745 739.049V619.346Z" fill="#6DBFF5" fill-opacity="0"/>\n' +
		'<path id="49" d="M327 537.532V641C348.019 640.213 374.987 637.459 412.951 633.525C423.316 632.089 441.326 630.517 462.192 628.697C487.051 626.528 515.964 624.005 540.834 620.936C546.815 620.403 553.254 619.79 559.869 619.16C582.56 617 607.331 614.641 622.852 614.641C650.219 614.641 677.977 615.821 677.977 615.821C677.977 615.821 729.93 618.575 745 618.969V497.728C729.533 497.01 680.357 491.108 680.357 491.108C680.357 491.108 650.613 488.355 622.852 489.141C602.805 489.141 569.313 494.649 540.759 499.764C525.689 502.911 455.89 515.107 412.662 522.582C399.261 525.301 368.082 530.573 344.994 534.477C338.02 535.656 331.784 536.71 327 537.532Z" fill="#6DBFF5" fill-opacity="0"/>\n' +
		'<path id="50" d="M745 378.584V497.996C729.533 497.274 680.357 491.343 680.357 491.343C680.357 491.343 650.613 488.575 622.852 489.366C602.805 489.366 569.313 494.901 540.759 500.041C525.689 503.205 455.89 515.462 412.662 522.975C395.213 526.533 347.622 534.441 327 538V435.195C331.408 434.121 336.299 432.975 341.804 431.684C358.589 427.75 381.078 422.478 412.951 413.844C443.596 406.331 507.767 390.515 540.759 381.421C585.97 368.958 602.805 366 622.852 366C650.219 366 678.374 368.768 678.374 368.768C678.374 368.768 729.533 376.28 745 378.584Z" fill="#6DBFF5" fill-opacity="0"/>\n' +
		'<path id="51" d="M745 257.555V379.373C729.533 377.068 678.374 369.554 678.374 369.554C678.374 369.554 650.219 366.785 622.852 366.785C602.805 366.785 585.97 369.744 540.759 382.21C507.767 391.307 443.596 407.127 412.951 414.642C370.624 426.112 344.846 431.649 327 436V331.98C370.096 317.61 466.439 285.626 507.049 272.653C520.702 268.291 532.978 264.045 544.278 260.135C574.993 249.509 598.499 241.377 622.852 240.221C650.188 238.922 677.977 243.78 677.977 243.78C677.977 243.78 731.001 254.08 745 257.555Z" fill="#6DBFF5" fill-opacity="0"/>\n' +
		'<path id="52" d="M745 257.345V134.115C740.77 133.332 726.095 129.928 700.979 123.547C660.924 113.369 644.664 112.196 622.852 113.37C607.326 114.205 586.763 120.024 578.435 123.547L327 225.708V331C370.096 316.778 466.439 285.125 507.049 272.287C557.812 256.238 589.539 241.756 622.852 240.19C650.188 238.905 677.977 243.713 677.977 243.713C677.977 243.713 731.001 253.906 745 257.345Z" fill="#6DBFF5" fill-opacity="0"/>\n'
}