<template>
	<div class="popup">
		<v-dialog v-model="dialog" persistent max-width="800px">
			<template v-slot:activator="{ on, attrs }">
				<v-btn v-bind="attrs" v-on="on" :class="class1" class="popup-btn-gtm">
					<slot name="default"></slot>
				</v-btn>
			</template>
			<v-card-actions class="pa-0 mb-6 justify-center d-md-none">
				<v-btn class="popup__close" text @click="close">
					<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
						<path
							d="M20.125 8.65735L19.3434 7.875L14.0004 13.218L8.65809 7.875L7.875 8.65588L13.218 13.9996L7.87574 19.3419L8.65662 20.125L14.0004 14.7812L19.3426 20.1243L20.125 19.3434L14.782 13.9996L20.125 8.65735Z"
							fill="#252E34"/>
					</svg>
				</v-btn>
			</v-card-actions>
			<v-card class="px-6 py-7 px-md-12 py-md-12">
				<v-form @submit.prevent="submit">
					<v-card-title class="popup__title">
						<span class="popup__title--hidden" v-model="form.TITLE"> {{ title }} </span>
						<h3 v-if="heading" class="h3 text-center text-md-left">
							Узнать цену
							<span class="popup__subtitle mt-3 mt-md-5">Узнать актуальную цену или задать любой вопрос Вы можете в отделе продаж по номеру телефона:
							<a href="tel:88129185518">8 (812) 918 55 18</a>. Или оставьте заявку, а мы свяжемся с Вами в ближайшее время.</span>
						</h3>
						<h3 v-else class="h3">
							Оставьте заявк<span @click.ctrl="fillForm">у</span>
							<span class="popup__subtitle mt-3 mt-md-5">А мы свяжемся с вами<br class="d-md-none"> в ближайшее время</span>
						</h3>
						<v-card-actions class="d-none d-md-block pa-0 mb-6 justify-center ">
							<v-btn class="popup__close" text @click="close">
								<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
									 fill="none">
									<path
										d="M20.125 8.65735L19.3434 7.875L14.0004 13.218L8.65809 7.875L7.875 8.65588L13.218 13.9996L7.87574 19.3419L8.65662 20.125L14.0004 14.7812L19.3426 20.1243L20.125 19.3434L14.782 13.9996L20.125 8.65735Z"
										fill="#252E34"/>
								</svg>
							</v-btn>
						</v-card-actions>
					</v-card-title>
					<v-card-text>
						<v-text-field class="popup__input"
									  v-model="form.NAME"
									  label="Ваше имя"
									  type="text"
									  required
									  regular
									  hide-details
						></v-text-field>
						<v-text-field class="popup__input"
									  v-model="form.PHONE"
									  @keydown="filterPhoneKey"
									  @keyup="fixPhone"
									  label="Ваш телефон"
									  required
									  variant="solo"
									  density="comfortable"
									  hide-details
									  :maxlength="phoneMaxlength"
						></v-text-field>
						<v-text-field class="popup__input"
									  v-model="form.EMAIL"
									  label="Ваш e-mail"
									  type="email"
									  required
									  regular
									  hide-details
						></v-text-field>
					</v-card-text>
					<v-card-actions class="pa-0">
						<v-spacer></v-spacer>
						<!--<v-btn text @click="close">
							Закрыть
						</v-btn>-->
						<v-btn class="popup__btn" text type="submit">
							Отправить
						</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>

			<v-dialog v-model="isMessageDialogShown" max-width="500px">
				<v-card-actions class="pa-0 mb-6 justify-center">
					<v-btn class="popup__close" text @click="closeMessageDialog">
						<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
							<path
								d="M20.125 8.65735L19.3434 7.875L14.0004 13.218L8.65809 7.875L7.875 8.65588L13.218 13.9996L7.87574 19.3419L8.65662 20.125L14.0004 14.7812L19.3426 20.1243L20.125 19.3434L14.782 13.9996L20.125 8.65735Z"
								fill="#252E34"/>
						</svg>
					</v-btn>
				</v-card-actions>
				<v-card class="px-6 py-7 px-md-12 py-md-12">
					<div class="status-message text-center">{{ message }}</div>
				</v-card>
			</v-dialog>
		</v-dialog>
	</div>
</template>

<script>
import BtnForm from "@/components/btn-form";
import Config from "@/config";
import axios from "axios";

export default {
	components: {
		BtnForm,
	},
	props: {
		class1: String,
		title: String,
		heading: true
	},
	data: () => ({
		dialog: false,
		isMessageDialogShown: false,
		form: {
			NAME: null,
			PHONE: null,
			EMAIL: null,
			TITLE: null
		},
		loading: false,
		message: null,
		phoneMaxlength: 32,
	}),
	methods: {
		submit() {
			this.form.TITLE = this.title;
			const reName = /^.{2,}$/;
			if (!reName.test(this.form.NAME)) {
				this.isMessageDialogShown = true;
				return (this.message = "Пожалуйста, введите Ваше имя.");
			}

			const phone = this.form.PHONE.replace(/\D+/g, "");
			const rePhone = /^\d{10,24}$/;
			if (!rePhone.test(phone)) {
				this.isMessageDialogShown = true;
				return (this.error = "Пожалуйста, укажите Ваш номер телефона.");
			}

			const reEmail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
			if (!reEmail.test(this.form.EMAIL)) {
				this.isMessageDialogShown = true;
				return (this.message = "Пожалуйста, введите Ваш email.");
			}

			const reTitle = /^.{2,}$/;
			if (!reTitle.test(this.form.TITLE)) {
				this.isMessageDialogShown = true;
				return (this.message = "Пожалуйста, введите Ваше имя.");
			}


			this.loading = true;
			this.message = null;
			this.$store.dispatch('post', {
				action: 'FeedbackController',
				params: this.form
			}).then((res) => {
				this.message = "Спасибо! Наш менеджер свяжется с вами в ближайшее время."
				this.isMessageDialogShown = true;
				this.dialog = false;
			}).catch((error) => {
				console.log("ERROR: ", error);
				this.message = "Произошла ошибка. Пожалуйста, попробуйте снова."
				this.isMessageDialogShown = true;
			}).finally(() => {
				this.loading = false;
				this.clearForm();
			});
		},
		filterPhoneKey(e) {
			//console.log(e);
			const a = e.key;

			// ignore arrows, del, backspace
			if (
				e.keyCode === 37 ||
				e.keyCode === 39 ||
				e.keyCode === 8 ||
				e.keyCode === 46 ||
				e.keyCode === 35 ||
				e.keyCode === 36 ||
				e.keyCode === 9 ||
				(e.key === "x" && e.ctrlKey) ||
				(e.key === "c" && e.ctrlKey) ||
				(e.key === "v" && e.ctrlKey)
			)
				return true;

			if (!this.validatePhoneKey(a)) e.preventDefault();
		},
		validatePhoneKey(a) {
			if (!a.match(/[+0-9\(\) -]/)) return false;

			let l = this.form.PHONE?.length;
			//console.log("a: "+a+", l: "+l);
			if (l === 0) {
				if (!a.match(/[+0-9]/)) return false;
				if (a === "+") this.form.PHONE = "";
				else if (a !== "8") this.form.PHONE = "+";
				else if (a === "9") this.form.PHONE = "+7 (";
			} else if (l === 1) {
				if (this.form.PHONE === "+" && a === "8") this.form.PHONE = "";
			}
			return true;
		},
		fixPhone(e) {
			if (
				e &&
				(e.keyCode === 37 ||
					e.keyCode === 39 ||
					e.keyCode === 8 ||
					e.keyCode === 46 ||
					e.keyCode === 35 ||
					e.keyCode === 36)
			)
				return true;

			let s = this.form.PHONE;
			s = s.replace(/[^0-9]+/g, "");
			//console.log("s=" + s);
			//v = v.substr(1);

			this.phoneMaxlength = 32;
			if (!s.length) return "";
			const f = [];
			for (let i = 0; i < s.length; i++) {
				let l = s[i];
				//if ( i === 0 && l === "+" )
				//console.log(i + "=" + l);

				if (s[0] === "8") {
					// 8 ...
					this.phoneMaxlength = 17;
					if (i === 1) {
						f.push(" ");
						f.push("(");
					} else if (i === 4) {
						f.push(")");
						f.push(" ");
					} else if (i === 7) {
						f.push("-");
					} else if (i === 9) {
						f.push("-");
					}
					f.push(l);
				} else if (s[0] === "7") {
					// +7 ...
					this.phoneMaxlength = 18;
					if (i === 0) {
						f.push("+");
					} else if (i === 1) {
						f.push(" ");
						f.push("(");
					} else if (i === 4) {
						f.push(")");
						f.push(" ");
					} else if (i === 7) {
						f.push("-");
					} else if (i === 9) {
						f.push("-");
					}
					f.push(l);
				} else if (s[0] === "9") {
					// 9 ...
					this.phoneMaxlength = 18;
					if (i === 0) {
						f.push("+");
						f.push("7");
						f.push(" ");
						f.push("(");
					} else if (i === 4) {
						f.push(")");
						f.push(" ");
					} else if (i === 7) {
						f.push("-");
					} else if (i === 9) {
						f.push("-");
					}
					f.push(l);
				} else {
					// [0-6] ...
					this.phoneMaxlength = 24;
					if (i === 0) {
						f.push("+");
					} else if (i === 3) {
						f.push(" ");
					}
					f.push(l);
				}
			}
			const p = f.join("");
			if (p === this.form.PHONE) return;
			this.form.PHONE = p;
		},
		close() {
			this.dialog = false;
			this.clearForm();
		},
		closeMessageDialog() {
			this.message = '';
			this.isMessageDialogShown = false;
		},
		clearForm() {
			for (const [key] of Object.entries(this.form)) {
				this.form[key] = null;
			}
		},
		fillForm() {
			this.form.NAME = 'TEST PLAYNEXT';
			this.form.PHONE = '+7 (911) 999-99-99';
			this.form.EMAIL = 'test@playnext.ru';
		}
	},

	mounted() {
		/*console.log('this.form', this.form);
		console.log('this.title', this.title);
		this.form.TITLE = this.title;
		console.log('this.form.TITLE', this.form.TITLE);*/
	}
};
</script>

<style lang="scss">
.popup {
	.v-card__title {
		//word-break: break-word !important;
		//flex-wrap: unset !important;
	}
	h3 {
		font-size: 30px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%;
	}

	&__subtitle {
		font-family: $default-font-family;
		font-size: 18px;
		font-style: normal;
		font-weight: 300;
		line-height: 130%;
		display: block;
		text-align: center;
		margin-bottom: 24px;
		@include up($md) {
			text-align: left;
			font-size: 20px;
			font-weight: 400;
		}
	}

	&__input {
		padding: 16px 20px;
		border: 1px solid #C1CFD8;
		margin-bottom: 12px;
		font-size: 14px;
		@include up($md) {
			padding: 16px 24px;
			margin-bottom: 16px;
			font-size: 20px;
		}
	}

	&__btn {
		font-size: 20px;
		font-style: normal;
		font-weight: 300;
		line-height: 120%;
		margin-bottom: 15px;
		width: 100%;
		@include up($md) {
			margin-bottom: 30px;
		}
	}

	&__title {
		@include up($md) {
			flex-direction: row !important;
			justify-content: space-between;
			align-items: baseline;
		}

		&--hidden {
			display: none;
		}
	}

	&__close {
		width: 40px !important;
		height: 40px !important;
		padding: 6px !important;
		border-radius: 50% !important;
		background: $white !important;
		min-width: unset !important;
	}


	.v-btn {
		font-family: $default-font-family;
		font-size: 20px;
		letter-spacing: unset;
		text-indent: unset;
		text-transform: none;
		width: 100%;
		background-color: $white;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 130%;
		position: relative;
		z-index: 1;
		padding: 16px 16px !important;
		border: 1.5px solid $primary-2;
		border-radius: 0;
		@include transition();
		@include up($md) {
			max-width: 198px;
		}

		&:hover {
			color: $white !important;
			background-color: $primary-1 !important;
		}

		&:active {
			color: $primary-2 !important;
			background-color: $primary-1 !important;
		}

		&:disabled {
			background-color: $light-grey !important;
			color: $dark-grey !important;
		}

		&:before {
			background-color: $white;
		}
	}

	.v-btn:not(.v-btn--round).v-size--default {
		height: unset;
	}

	.v-btn--is-elevated {
		box-shadow: unset;
	}

	.style1 {
		border: none !important;
		padding: 0 !important;

		&:hover {
			color: unset !important;
			background-color: unset !important;
		}

		&:active {
			color: unset !important;
			background-color: unset !important;
		}

		&:disabled {
			background-color: unset !important;
			color: unset !important;
		}
	}
}
</style>